.slider-holder {
    margin-bottom: 50px;
    overflow: hidden;
    width: 100%;
    height: auto;
    position: relative;
}

.slider {
    overflow: hidden;
    display: block;
    height: 100%;

    &.is-animating {
        transition: transform 0.8s ease-out;
    }
}

@for $i from 1 through 20 {
    .slider-#{$i} {
        width: 100% * $i;
    }
}

.slider-panel {
    position: relative;
    float: left;
    height: 100%;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
}

@for $i from 1 through 20 {
    .slider-#{$i} .slider-panel {
        width: 100% / $i;
    }
}

.has-slider {
    overflow: hidden;
    position: relative;
    width: 100%;
    height: 100%;
}

.slider .slide img {
    width: 100%;
    height: auto;
    display: block;
    pointer-events: none;
}

.slider-holder .slide-controls {
    position: absolute;
    display: none;
    top: 50%;
    right: 20px;
    transform: translateY(-50%);
    z-index: 2;

    @media only screen and (min-width: 768px) {
        display: block;

        &::before {
            content: '';
            position: absolute;
            display: block;
            right: 30px;
            top: 22px;
            height: 1px;
            width: 30px;
            background-color: #fff;
        }
    }

    @media only screen and (min-width: 1024px) {
        right: 30px;
    }

    button {
        -webkit-appearance: none;
        border: none;
        background: none;
        font-family: CalibreWeb;
        font-weight: bold;
        padding: 8px;
        color: #fff;
        font-size: em(24);
        cursor: pointer;

        &:focus {
            outline: none;
        }
    }

    button.previous {
        margin-left: 30px;
    }

    button.next,
    button.previous {
        transition: transform 0.4s ease;
    }

    button.next:hover,
    button.previous:hover {
        transform: scale(2);
    }
}
