/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable selector-id-pattern */
#cookie-message {
    position: fixed;
    left: 0;
    bottom: -5px;
    width: 100%;
    height: auto;
    background-color: rgba(52, 196, 173, 0.85);
    z-index: 10;
    text-align: center;
    padding: 30px 20px 30px;
    box-sizing: border-box;
    transform: translateY(100%);
    transition: transform 0.8s ease;
    transition-delay: 1.6s;

    p {
        color: #fff;
        margin: 0;
        margin-bottom: 30px;
        line-height: 1.4;

        a {
            color: #fff;
            text-decoration: underline;
        }
    }

    &.visible {
        transform: translateY(0);
    }

    .btn {
        display: inline-block;
        margin: 0 auto;
        cursor: pointer;
        border: 1px solid #fff;
        padding: 5px 10px 8px;
        color: #fff;
        font-weight: bold;
    }
}

@media only screen and (min-width: 768px) {
    #cookie-message {
        .cookie-holder {
            width: 640px;
            margin: 0 auto;
            position: relative;
        }

        p {
            width: 450px;
            text-align: left;
            float: left;
            margin-bottom: 0;
        }

        .btn {
            padding: 10px 15px 13px;
            font-size: 1.5em;
            margin-top: 15px;
            margin-left: 70px;
        }
    }
}

#CybotCookiebotDialog a,
#CybotCookiebotDialog div,
#CybotCookiebotDialogBodyContentControls {
    font-family: CalibreWeb !important;
    font-size: 14px !important;
}

#CybotCookiebotDialogBody {
    font-family: CalibreWeb !important;
    width: 860px !important;
    max-width: 90% !important;

    #CybotCookiebotDialogBodyContentTitle {
        margin-bottom: 20px !important;
        margin-top: 20px !important;
        font-family: CalibreWeb !important;
        font-size: 20px;
    }

    #CybotCookiebotDialogBodyContentText {
        font-size: 16px !important;
        line-height: 1.25 !important;
        margin-bottom: 10px !important;
        font-family: CalibreWeb !important;
    }
}

#CybotCookiebotDialogBodyLevelButtonLevelOptinAllowallSelectionWrapper {
    float: left !important;
}

.CybotCookiebotDialogBodyButton {
    width: auto !important;
}

a#CybotCookiebotDialogBodyLevelButtonLevelOptinDeclineAll {
    border: 1px solid white !important;
    padding: 15px 20px !important;
}

a#CybotCookiebotDialogBodyLevelButtonLevelOptinAllowallSelection {
    border: 1px solid white !important;
    padding: 15px 20px !important;
}

a#CybotCookiebotDialogBodyLevelButtonAccept,
a#CybotCookiebotDialogBodyLevelButtonLevelOptinAllowAll {
    background: white !important;
    color: black !important;
    padding: 15px 20px !important;
    border: 1px solid white !important;
}

#CybotCookiebotDialogBodyLevelButtonLevelOptinAllowallSelectionWrapper {
    margin-bottom: 15px;
}

#CybotCookiebotDialog {
    // background: rgba(52,196,173,0.85) !important;
    box-shadow: none !important;
}

// input[type=checkbox].CybotCookiebotDialogBodyLevelButton+label {
//     background-image: none !important;
// }

// input[type=checkbox].CybotCookiebotDialogBodyLevelButton:checked+label {
//     background-image: none !important;
// }

// input[type=checkbox].CybotCookiebotDialogBodyLevelButton {
//     opacity: .95 !important;
// }

// input[type=checkbox].CybotCookiebotDialogBodyLevelButton:checked {
//     opacity: .6 !important;
// }

#CybotCookiebotDialogBodyLevelDetailsWrapper {
    background-color: transparent !important;
    border: none !important;

    a#CybotCookiebotDialogBodyLevelDetailsButton {
        padding-right: 25px;
        font-weight: bold;
    }

    #CybotCookiebotDialogBodyLevelDetailsButton {
        background-image: url('/media/images/arrow-down-white.svg');

        &.CybotCookiebotDialogBodyLevelDetailsButtonExpanded {
            background-image: url('/media/images/arrow-up-white.svg') !important;
        }
    }
}

#CybotCookiebotDialogBodyLevelButtons {
    margin-bottom: 15px;
}

#CybotCookiebotDialogBodyLevelButtonsRow {
    display: flex !important;
    justify-content: space-between;
    border: 1px solid white;
    padding: 15px;

    #CybotCookiebotDialogBodyLevelButtonsSelectPane {
        display: flex !important;
        border: none !important;
    }
}

#CybotCookiebotDialogDetailBody {
    font-family: CalibreWeb !important;
    width: 860px !important;
    max-width: 90% !important;
}

.CybotCookiebotDialogDetailBodyContentTabsItem {
    background-color: transparent !important;
}

#CybotCookiebotDialogDetailBodyContentTabs a {
    padding: 15px 25px;
}

a.CybotCookiebotDialogDetailBodyContentTabsItemSelected {
    font-weight: bold;
}

// .CybotCookiebotDialogBodyLevelButtonWrapper {
input[type='checkbox'].CybotCookiebotDialogBodyLevelButton + label {
    line-height: 20px !important;
    padding-left: 25px !important;
    background-image: url('/media/images/empty-checkbox.png') !important;
}
// }

input[type='checkbox'].CybotCookiebotDialogBodyLevelButton.CybotCookiebotDialogBodyLevelButtonDisabled + label {
    background-image: url('/media/images/necessary-checkbox.png') !important;
    opacity: 0.7;
}

input[type='checkbox'].CybotCookiebotDialogBodyLevelButton:checked + label {
    background-image: url('/media/images/necessary-checkbox.png') !important;
}
