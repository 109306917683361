.picl-button {
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    text-decoration: none !important;

    .time-tickets & {
        margin-top: 10px;
    }
}

.picl-button__text {
    margin-right: 10px;
    color: $fontColor;
    font-weight: bold;
    font-size: 15px;

    .picl-button.big & {
        font-size: 20px;
    }
}

.picl-button__icon {
    width: 36px;
    height: 26px;

    .picl-button.big & {
        width: 55px;
        height: 40px;
    }
}
