#slider {
    position: relative;
    height: 250px;
    overflow: hidden;

    &::after {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background-color: #000;
        opacity: 0.1;
    }

    .slide {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        transition: transform 1s ease;

        img {
            display: block;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    #trailer {
        visibility: hidden;
    }

    .plyr {
        opacity: 0;
        visibility: hidden;
        transform: translateY(0px);

        @media only screen and (min-width: 768px) {
            transform: translateY(-32px);
        }

        @media only screen and (min-width: 1024px) {
            transform: translateY(-45px);
        }

        @media only screen and (min-width: 1280px) {
            transform: translateY(-52px);
        }

        @media only screen and (min-width: 1440px) {
            transform: translateY(-60px);
        }

        @media only screen and (min-width: 1680px) {
            transform: translateY(-75px);
        }

        .plyr__control--overlaid[data-plyr='play'] {
            display: none !important;
        }
    }

    .plyr--video .plyr__controls {
        bottom: 0px;

        @media only screen and (min-width: 768px) {
            bottom: 32px;
        }

        @media only screen and (min-width: 1024px) {
            bottom: 55px;
        }

        @media only screen and (min-width: 1440px) {
            bottom: 60px;
        }

        @media only screen and (min-width: 1680px) {
            bottom: 75px;
        }
    }

    .plyr__progress--played,
    .plyr__volume--display {
        color: #fff;
    }

    .plyr__controls button {
        border-radius: 0px;
    }

    .plyr .plyr__play-large {
        display: none;
    }

    .plyr input[type='range'] {
        &:active {
            &::-webkit-slider-thumb {
                background: #70c4d0;
                border-color: #fff;
                transform: scale(1.2);
            }

            &::-moz-range-thumb {
                background: #70c4d0;
                border-color: #fff;
                transform: scale(1.2);
            }

            &::-ms-thumb {
                background: #70c4d0;
                border-color: #fff;
                transform: scale(1.2);
            }
        }
    }

    .plyr--video .plyr__controls button.tab-focus:focus,
    .plyr--video .plyr__controls button:hover {
        background: #70c4d0;
    }

    .slider-navigation {
        position: relative;
    }

    .play {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 84px;
        height: 84px;
        text-indent: -999999em;
        overflow: hidden;
        cursor: pointer;
        background: url('/media/images/icn-play.svg') 0 0 no-repeat;
        background-size: contain;
        transform: translate(-50%, -50%);
        z-index: 2;
    }
}

// Style for the slider navigation, duplicate of above but legacy.
/* stylelint-disable-next-line no-duplicate-selectors */
#slider .slider-navigation {
    position: absolute;
    display: none;
    top: 50%;
    right: 20px;
    transform: translateY(-50%);
    z-index: 2;

    @media only screen and (min-width: 768px) {
        display: block;

        &::before {
            content: '';
            position: absolute;
            display: block;
            right: 30px;
            top: 22px;
            height: 1px;
            width: 30px;
            background-color: #fff;
        }
    }

    @media only screen and (min-width: 1024px) {
        right: 30px;
    }

    button {
        -webkit-appearance: none;
        border: none;
        background: none;
        font-family: CalibreWeb;
        font-weight: bold;
        padding: 8px;
        color: #fff;
        font-size: em(24);
        cursor: pointer;

        &:focus {
            outline: none;
        }
    }

    button.previous {
        margin-left: 30px;
    }

    button.next,
    button.previous {
        transition: transform 0.2s ease;
    }

    button.next:hover,
    button.previous:hover {
        transform: scale(1.4);
    }
}

.trailer-playing #slider {
    .slide {
        opacity: 1;
        pointer-events: none;
    }

    &::after {
        display: none;
    }

    #trailer,
    .plyr {
        opacity: 1;
        visibility: visible;
    }

    .slider-navigation {
        display: none;
    }
}

.trailer-fullscreen #slider .plyr {
    transform: translate(0) !important;
}

.trailer-fullscreen #slider .plyr--video .plyr__controls {
    bottom: 0px !important;
}

.trailer-playing .inner-container {
    transform: translateY(90px);
}

#moviepage,
#movieseriespage,
#movieeventpage,
#moviefestivalpage {
    .movie-intro .labels .breadcrumb {
        a {
            transition: color 0.4s ease;

            svg {
                transition: fill 0.4s ease;
            }
        }

        span {
            transition: color 0.4s ease;
        }
    }
}

.trailer-playing {
    #moviepage,
    #movieseriespage,
    #movieeventpage,
    #moviefestivalpage {
        .movie-intro .labels .breadcrumb {
            a {
                color: $fontColor;

                svg {
                    fill: $fontColor;
                }
            }

            span {
                color: $fontColor;
            }
        }
    }
}

.inner-container {
    transition: transform 0.6s ease;
}

@media only screen and (max-width: 767px) {
    .trailer-playing .slide {
        visibility: hidden;
    }
}

@media only screen and (min-width: 768px) {
    #slider {
        height: 340px;
        margin-top: 30px;
    }

    .trailer-playing .inner-container {
        transform: translateY(165px);
    }
}

@media only screen and (min-width: 1024px) {
    #slider {
        height: 450px;
    }
}

@media only screen and (min-width: 1280px) {
    #slider {
        height: 550px;
    }
}

@media only screen and (min-width: 1440px) {
    #slider {
        height: 600px;
    }
}

@media only screen and (min-width: 1680px) {
    #slider {
        height: 750px;
    }
}

// Transitions
.slide-next-enter {
    transform: translateX(100%);
}

.slide-next-leave-to {
    transform: translateX(-100%);
}

.slide-previous-enter {
    transform: translateX(-100%);
}

.slide-previous-leave-to {
    transform: translateX(100%);
}

.festival-list .item {
    .trailer {
        visibility: hidden;
    }

    .plyr {
        opacity: 0;
        visibility: hidden;
        transform: translateY(0px);

        @media only screen and (min-width: 768px) {
            transform: translateY(-18px);
        }

        @media only screen and (min-width: 1024px) {
            transform: translateY(-30px);
        }

        @media only screen and (min-width: 1440px) {
            transform: translateY(-35px);
        }
    }

    .plyr--video .plyr__controls {
        bottom: 0px;

        @media only screen and (min-width: 768px) {
            bottom: 5px;
        }

        @media only screen and (min-width: 1024px) {
            bottom: 28px;
        }

        @media only screen and (min-width: 1440px) {
            bottom: 36px;
        }
    }

    .plyr__progress--played,
    .plyr__volume--display {
        color: #fff;
    }

    .plyr__controls button {
        border-radius: 0px;
    }

    .plyr input[type='range'] {
        &:active {
            &::-webkit-slider-thumb {
                background: #70c4d0;
                border-color: #fff;
                transform: scale(1.2);
            }

            &::-moz-range-thumb {
                background: #70c4d0;
                border-color: #fff;
                transform: scale(1.2);
            }

            &::-ms-thumb {
                background: #70c4d0;
                border-color: #fff;
                transform: scale(1.2);
            }
        }
    }

    .plyr--video .plyr__controls button.tab-focus:focus,
    .plyr--video .plyr__controls button:hover {
        background: #70c4d0;
    }

    .slider-navigation {
        position: relative;
    }

    .play {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 84px;
        height: 84px;
        text-indent: -999999em;
        overflow: hidden;
        cursor: pointer;
        background: url('/media/images/icn-play.svg') 0 0 no-repeat;
        background-size: contain;
        transform: translate(-50%, -50%);
        z-index: 2;
        opacity: 0;
        pointer-events: none;
        transition: opacity 0.6s ease;
    }
}

.festival-list .item.active,
.festival-list .item.active .trailer-playing {
    .play {
        opacity: 1;
        pointer-events: initial;
    }
}

.festival-list .item .trailer-playing {
    img {
        opacity: 1;
        pointer-events: none;
    }

    .trailer,
    .plyr {
        opacity: 1;
        visibility: visible;
    }
}

.trailer-playing #slider.normal-aspect-ratio {
    @media only screen and (min-width: 768px) {
        height: 405px;
    }

    @media only screen and (min-width: 1024px) {
        height: 540px;
    }

    @media only screen and (min-width: 1280px) {
        height: 664px;
    }

    @media only screen and (min-width: 1440px) {
        height: 720px;
    }

    @media only screen and (min-width: 1680px) {
        height: 900px;
    }

    .plyr {
        @media only screen and (min-width: 768px) {
            transform: translateY(0px);
        }
    }

    .plyr--video .plyr__controls {
        bottom: 0;
    }
}
