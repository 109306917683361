#ticket-holder {
    height: 0px;
    overflow: hidden;
}

#ticket-holder.open {
    height: 750px;
}

#ticket-holder iframe {
    border: none;
    width: 100%;
    height: 100%;
    opacity: 0;
}
