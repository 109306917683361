nav {
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: #fff;
    top: 0px;
    left: 0;
    z-index: 9;
    box-sizing: border-box;
    padding: 60px 0px;
    transform: translateY(-100%);
    transition: transform 0.6s ease;

    @media only screen and (max-width: 767px) and (max-height: 559px) {
        overflow-y: scroll;
    }

    .inner {
        padding-top: 25px;
        opacity: 0;
        transition: opacity 0.2s ease;

        .block1,
        .block2,
        .block3,
        .block4 {
            transform: translateY(50px);
            opacity: 0;
            transition: opacity 0.5s 0.5s ease, transform 0.5s 0.5s ease;
        }

        .block2 {
            transition-delay: 0.6s;
        }

        .block3 {
            transition-delay: 0.7s;
        }

        .block4 {
            transition-delay: 0.8s;
        }
    }

    ul {
        padding: 0;
        margin: 0;
        font-size: 1em;
    }

    ul li {
        list-style: none;
    }

    ul li a {
        text-decoration: none;
        color: $fontColor;
    }

    .block1 {
        ul {
            width: 290px;
            margin: 0 auto;
            border-bottom: 1px solid #f3f3f5;
        }

        ul li {
            display: inline-block;
            margin-bottom: 30px;
            width: 100%;
        }

        ul li a {
            position: relative;
            font-size: 1.5em;
            font-family: CalibreWeb;
            font-weight: bold;

            &.active {
                &::after {
                    content: '';
                    position: absolute;
                    bottom: -5px;
                    left: 0;
                    width: 100%;
                    height: 3px;
                    background-color: $color1;
                }
            }
        }
    }

    .block2 {
        padding-top: 15px;
        width: 290px;
        margin: 0 auto;

        ul {
            width: 145px;
            float: left;
        }

        ul li {
            display: inline-block;
            margin-bottom: 30px;
            width: 100%;
        }

        ul li a {
            text-transform: lowercase;
            font-size: 0.95em;
            font-family: CalibreWeb;

            &.active {
                &::after {
                    content: '';
                    position: absolute;
                    bottom: -3px;
                    left: 0;
                    width: 100%;
                    height: 2px;
                    background-color: $color1;
                }
            }
        }

        ul.navgroup2 li:nth-child(5) {
            display: none;
        }

        ul.navgroup3 li:nth-child(4) a {
            position: relative;
            padding: 2px 0 0 13px;

            &::after {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                width: 50px;
                height: 30px;
                background: url('/media/images/border.svg') 0 0 no-repeat;
                background-size: 50px auto;
            }
        }
    }

    .block4 {
        display: none;
    }
}

nav.active {
    transform: translateY(0);

    .inner {
        opacity: 1;
        transition: opacity 0s ease;

        .block {
            opacity: 1;
            transform: translateY(0);
        }
    }
}

.no-touchevents nav .block1 ul li a:hover::after {
    content: '';
    position: absolute;
    bottom: -5px;
    left: 0;
    width: 100%;
    height: 3px;
    background-color: $color1;
}

.no-touchevents nav .block2 ul li a:hover::after {
    content: '';
    position: absolute;
    bottom: -3px;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: $color1;
}

@media only screen and (min-width: 768px) {
    nav {
        padding: 60px 30px 0;
        height: auto;

        .inner {
            width: 680px;
            margin: 0 auto;
            padding-top: 60px;
        }

        .block {
            border-bottom: none;
            float: left;
            display: inline-block;
            min-height: 300px;
            padding-top: 20px;
            padding-bottom: 30px;
            box-sizing: border-box;
        }

        .block1 {
            width: 297px;
            border-right: 1px solid #f3f3f5;

            ul {
                width: auto;
                margin: 0;
                border: 0;
            }

            ul li {
                display: block;
                margin-bottom: 45px;

                &:last-of-type {
                    margin-bottom: 0;
                }
            }

            ul li a {
                font-size: 2.25em;
            }
        }

        .block2 {
            padding-left: 40px;
            box-sizing: border-box;
            padding-right: 0;
            width: 373px;

            ul {
                width: 160px;
                margin: 13px 0 0;
            }

            ul li {
                display: block;
                margin-bottom: 61px;
            }

            ul li a {
                font-size: 1.25em;
            }

            ul.navgroup2 li:nth-child(5) {
                display: block;
                margin-bottom: 3px;
            }

            ul.navgroup3 li:nth-child(1) {
                display: none;
            }

            ul.navgroup3 li:nth-child(4) a {
                position: relative;
                padding: 2px 0 0 31px;

                &::after {
                    content: '';
                    position: absolute;
                    top: -8px;
                    left: -2px;
                    width: 101px;
                    height: 47px;
                    background: url('/media/images/border.svg') 0 0 no-repeat;
                    background-size: 101px 47px;
                }
            }
        }

        .block4 {
            display: block;
            box-sizing: border-box;
            width: 460px;
            margin: 0;
            border: none;
            padding: 38px 0 0;
            min-height: 280px;

            .follow {
                float: right;
                width: 120px;
            }

            .contact {
                float: left;
                width: 340px;
                font-family: CalibreWeb;
            }

            h3 {
                color: $fontColor;
                margin-bottom: 22px;
                font-family: CalibreWeb;
                font-weight: bold;
                font-size: 0.875em;
                text-transform: uppercase;
            }

            p {
                font-size: 1em;
                line-height: 1.8;
            }

            a.facebook {
                display: inline-block;
                text-indent: -999999em;
                overflow: hidden;
                width: 9px;
                height: 18px;
                background: url('/media/images/icn-facebook-dark.svg') 0 0 no-repeat;
                background-size: 9px 18px;
                margin-bottom: 60px;
            }

            a.instagram {
                display: inline-block;
                text-indent: -999999em;
                overflow: hidden;
                width: 17px;
                height: 17px;
                background: url('/media/images/icn-instagram-dark.svg') 0 0 no-repeat;
                background-size: 17px 17px;
                margin-bottom: 60px;
                margin-right: 20px;
            }

            a {
                color: $fontColor;
                text-decoration: none;
            }
        }
    }
}

@media only screen and (min-width: 1024px) {
    nav {
        padding: 60px 30px;

        .inner {
            width: 880px;
        }

        .block2 {
            width: 330px;
            padding-left: 40px;
            border-right: 1px solid #f3f3f5;

            ul {
                width: 144px;
            }
        }

        .block4 {
            width: 238px;
            padding-left: 35px;

            .follow {
                float: none;
                width: auto;
            }

            .contact {
                float: none;
                width: auto;
                margin-top: 80px;
            }
        }
    }
}

@media only screen and (min-width: 1280px) {
    nav {
        .inner {
            width: 1090px;
        }

        .block1 {
            width: 327px;
        }

        .block2 {
            width: 470px;
            padding-left: 60px;

            ul {
                width: 200px;
            }
        }

        .block4 {
            width: 268px;
            padding-left: 55px;
        }
    }
}

@media only screen and (min-width: 1440px) {
    nav {
        .inner {
            width: 1200px;
        }
    }
}

@media only screen and (min-width: 1680px) {
    nav {
        .inner {
            width: 1511px;
        }
    }
}
