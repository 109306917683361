.kids__cta-section {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 20px;
    padding: 0 20px;
    margin-bottom: 60px;
    margin-top: 30px;

    @media only screen and (min-width: 768px) {
        padding: 0 30px;
    }

    @media only screen and (min-width: 1440px) {
        padding: 0 60px;
    }
}

.kids__cta-block {
    width: 100%;
    background-color: $color4;
    color: white;
    padding: 25px;
    box-sizing: border-box;
    transition: all 0.4s ease-in-out;

    &.has-link {
        background-color: $color1;
        cursor: pointer;
    }

    p {
        margin-bottom: 0;
    }

    h4 {
        font-weight: 700;
        font-size: 1.25em;
        margin-bottom: 20px;
    }

    @media only screen and (min-width: 768px) {
        width: calc(50% - 20px / 2);

        h4 {
            font-size: 1.875em;
        }
    }
}

.kids__cta-block:hover {
    background-color: $color4;
}

.kids__cta-block-row {
    display: flex;
    justify-content: space-between;
}

.kids-image__holder {
    width: 100%;
    height: 250px;

    @media only screen and (min-width: 768px) {
        height: 340px;
        margin-top: 30px;
    }

    @media only screen and (min-width: 1024px) {
        height: 450px;
    }

    @media only screen and (min-width: 1280px) {
        height: 550px;
    }

    @media only screen and (min-width: 1440px) {
        height: 600px;
    }

    @media only screen and (min-width: 1680px) {
        height: 750px;
    }
}

.kids-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
}
