/* stylelint-disable no-invalid-position-at-import-rule */
@import 'fonts';
@import 'variables';
@import 'functions';
@import 'reset';

html,
body {
    background-color: $bgColor;
    color: $fontColor;
    font-family: CalibreWeb;
    font-style: normal;
    font-weight: normal;
    font-size: 100%;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizelegibility;
}

body.overlay-open,
body.overflow {
    overflow: hidden;
}

body.overlay-open {
    // Prevents body from scrolling when a modal is open.
    // DO NOT REMOVE.
    // -webkit-overflow-scrolling: touch;
    overflow-y: scroll;
}

@media only screen and (min-width: 768px) {
    body.overflow {
        overflow: visible;
    }
}

[v-cloak] {
    display: none !important;
}

@import 'header';
@import 'navigation';
@import 'page';
@import 'pages/home';
@import 'pages/movie';
@import 'pages/movie-series';
@import 'pages/movie-event';
@import 'pages/movie-festival';
@import 'pages/about';
@import 'pages/news';
@import 'pages/press';
@import 'pages/press-register';
@import 'pages/restaurant';
@import 'pages/movie-overview';
@import 'pages/movie-overview-list';
@import 'pages/search';
@import 'pages/archive';
@import 'pages/organize-single';
@import 'pages/kids';
@import 'components/slider';
@import 'components/schedule';
@import 'components/time-tickets';
@import 'components/accordion';
@import 'components/media-slider';
@import 'components/overlay';
@import 'components/datepicker';
@import 'components/ticket-holder';
@import 'components/cookie';
@import 'components/video';
@import 'components/popup';
@import 'components/picl-button';
@import 'components/video-player';
@import 'sidebar';
@import 'footer';
@import 'classes';
@import 'elements';
@import 'plugins/swiper';

@media only screen and (max-width: 767px) {
    #movieoverviewpage .movies-holder--grid .item.has-duplicates {
        display: none;
    }
}

#movieoverviewpage .movies-holder--list .item.has-duplicates {
    display: none;
}
