#movieseriespage {
    .movies-in-series {
        h2.title {
            font-size: 1.375em;
        }

        .article-blocks-holder {
            .article-block {
                .img-holder {
                    margin-bottom: 15px;
                    position: relative;

                    &::after {
                        opacity: 0;
                        content: '';
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        box-sizing: border-box;
                        background: url('/media/images/read-more-arrow-white.svg') 90% 50% no-repeat rgba(0, 0, 0, 0.3);
                        background-size: 32px 20px;
                        border-bottom: 3px solid $color2;
                        transition: opacity 0.3s ease;
                    }
                }

                .article-text {
                    height: auto;
                    padding: 0;
                    background: none;

                    h2 {
                        margin-bottom: 0;
                    }

                    p {
                        font-weight: bold;
                        text-transform: lowercase;
                        color: #9a968e;
                    }
                }

                &:hover {
                    .img-holder::after {
                        opacity: 0 !important;
                    }
                }
            }
        }
    }

    #sidebar .time-tickets-holder .time-tickets .item .date {
        font-weight: normal;
    }
}

.no-touchevents #movieseriespage .movies-in-series .article-blocks-holder .article-block:hover .img-holder::after {
    opacity: 1;
}

@media only screen and (min-width: 768px) {
    #movieseriespage {
        .movies-in-series {
            h2.title {
                font-size: 3em;
            }

            p {
                font-size: 1.125em;
            }
        }
    }

    #sidebar .time-tickets-holder {
        margin-bottom: 70px;
    }
}

@media only screen and (min-width: 1024px) {
    #movieseriespage {
        .movies-in-series {
            .article-blocks-holder {
                .article-block {
                    margin-bottom: 40px;
                }

                .article-block:nth-child(odd) {
                    clear: both;
                }
            }
        }
    }
}
