.popup__content {
    background-color: $color2;
    color: #feed5f;
    padding: 30px 20px;

    @media only screen and (min-width: 768px) {
        max-width: 700px;
        padding: 80px;
    }
}

.popup__content-title {
    font-size: 1.625em;
    margin-bottom: 20px;

    @media only screen and (min-width: 768px) {
        font-size: 3em;
    }
}

.popup__content-text p {
    margin-bottom: 0;
}

.read-more--popup {
    margin-top: 20px;
}

.no-touchevents .read-more--popup:hover svg {
    transform: translateX(10px);
}
