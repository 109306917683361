.fixed-dropdowns {
    position: sticky;
    top: 60px;
    width: 100%;
    z-index: 5;
    background-color: $bgColor;

    @media only screen and (max-width: 767px) {
        .text-indent {
            padding: 0 15px;
        }
    }
}

.headroom {
    transition: transform 0.6s ease;
}

.headroom--unpinned {
    transform: translateY(-135px);
}

#movieoverviewpage,
#archivepage {
    .options {
        background-color: transparent;
        transform: translateX(0px);
        padding: 0;
        width: 100%;
        margin-top: 20px;
        margin-bottom: 15px;

        @media only screen and (max-width: 767px) {
            padding-top: 20px;
            padding-bottom: 20px;
            margin: 0;
        }

        .current {
            display: none;
        }

        .list {
            display: block;
            position: relative;
            background-color: transparent;
            left: inherit;
            top: inherit;

            .option {
                font-size: 1.5em;
                font-weight: bold;
                padding: 0;
                margin-left: 20px;
                display: inline-block;
                position: relative;
                color: $fontColor;
                text-decoration: none;

                &:nth-child(1) {
                    margin-left: 0;

                    &::after {
                        display: none;
                    }
                }

                &::after {
                    content: '/';
                    position: absolute;
                    top: 0;
                    left: -16px;
                }

                &.active {
                    display: inline-block;

                    &::before {
                        content: '';
                        position: absolute;
                        bottom: -4px;
                        left: 0;
                        width: 100%;
                        height: 3px;
                        background-color: $color1;
                    }
                }
            }

            .option.date {
                padding-right: 25px;
            }
        }

        &.suboptions {
            border-top: none;
            margin-top: 25px;
            background-color: transparent;

            @media only screen and (max-width: 767px) {
                padding-top: 10px;
                margin: 0;
            }

            .list {
                .option {
                    font-size: 1.125em;
                    margin-left: 10px;
                    text-transform: lowercase;
                    cursor: pointer;

                    &:nth-child(1) {
                        margin-left: 0;
                    }

                    &::after {
                        display: none;
                    }
                }
            }
        }
    }

    .cat-info {
        margin-top: 135px;

        &.less-margin {
            margin-top: 90px;
        }

        &.no-margin-top {
            margin-top: 135px;
        }
    }

    .movies-holder {
        margin-top: 25px;

        &.less-margin {
            margin-top: 90px;
        }

        &.no-margin-top {
            margin-top: 0px;
        }

        .item {
            display: block;
            padding: 15px 0 18px;
            border-bottom: 1px solid #e7e3da;
            position: relative;

            .img-holder {
                display: none;
            }

            h2 {
                font-size: 1.34em;
                line-height: 1;
                margin-bottom: 4px;
            }

            h2 span {
                font-weight: 400;
                margin-right: 5px;
            }

            span.info {
                display: block;
                color: #9a968e;
                font-weight: bold;
                font-size: 1em;
                text-transform: lowercase;
                transition: color 0.3s ease;
            }

            .labels {
                position: absolute;
                bottom: 10px;
                right: 0;

                .label {
                    color: #9a968e;
                    font-weight: bold;
                    font-size: 0.65em;
                    text-transform: uppercase;
                    letter-spacing: 0.05em;
                    padding: 4px 8px;
                }
            }
        }

        .overlay {
            display: none;
        }
    }
}

@media only screen and (max-width: 767px) {
    #movieoverviewpage,
    #archivepage {
        .container .inner-container #content {
            padding: 0;
        }

        .movies-holder--grid {
            .item {
                padding: 0;
                margin-bottom: 20px;
                border-bottom: none;

                span.info {
                    display: none;
                }

                .img-holder {
                    display: block;
                    padding-bottom: 63%;

                    img {
                        position: absolute;
                        width: 100%;
                        height: 100%;
                        top: 0;
                        left: 0;
                        object-fit: cover;
                    }
                }

                .labels {
                    display: none;
                }

                .labels--mobile {
                    display: block;
                    left: 0;
                    right: auto;
                }

                .info-wrapper {
                    padding: 18px 15px 20px;
                    background-color: #fff;

                    h2 {
                        margin-bottom: 10px;
                    }

                    p {
                        margin-bottom: 0;
                        font-size: 0.875em;
                    }

                    .movie-info {
                        color: #70c4d0;
                        line-height: 1.4;
                        margin-top: 10px;
                        font-size: 0.75em;
                    }

                    .mobile-content {
                        display: block;
                    }

                    .time-ticket-info {
                        margin-top: 20px;

                        .time {
                            width: 55px;
                            height: 35px;
                            float: left;
                            margin-left: 5px;
                            background-color: #ff6f5c;
                            color: #fff;
                            font-size: 0.875em;
                            text-align: center;
                            font-weight: bold;
                            padding-top: 10px;
                            box-sizing: border-box;
                            margin-bottom: 5px;

                            &:first-of-type {
                                margin-left: 0;
                            }

                            &:hover::after {
                                content: 'ticket';
                                position: absolute;
                                top: 0;
                                left: 0px;
                                width: 100%;
                                height: 100%;
                                background-color: #cc4e3d;
                                color: #fff;
                                box-sizing: border-box;
                                padding-top: 10px;
                            }
                        }
                    }

                    .link-to-entry {
                        display: inline-block;
                        padding: 11px 12px;
                        margin-left: 0;
                        background-color: #ff6f5c;
                        color: #fff;
                        font-size: 0.875em;
                        text-align: center;
                        font-weight: 700;
                        box-sizing: border-box;
                        line-height: 1;

                        &:hover {
                            background-color: #cc4e3d;
                        }
                    }

                    .expected-on {
                        display: inline-block;
                        padding: 11px 12px;
                        margin-left: 0;
                        background-color: #3b3530;
                        color: #fff;
                        font-size: 0.875em;
                        text-align: center;
                        font-weight: 700;
                        box-sizing: border-box;
                        line-height: 1;
                    }
                }
            }
        }
    }
}

#archivepage .movies-holder {
    margin-top: 40px;
}

#archivepage .results-info {
    margin-top: 25px;
}

@media only screen and (min-width: 768px) {
    .fixed-dropdowns {
        position: relative;
        left: inherit;
        top: inherit;
        width: auto;
    }

    .headroom {
        transition: none;
    }

    .headroom--unpinned {
        transform: translateY(0px);
    }

    #movieoverviewpage,
    #archivepage {
        .movies-holder--grid .item .info-wrapper .mobile-content {
            display: none;
        }

        .container .inner-container #content {
            width: 100%;
            margin-right: 0;
        }

        .options {
            background-color: transparent;
            transform: translateX(0px);
            padding: 0;
            width: 100%;
            margin-top: 0;
            margin-bottom: 15px;

            .current {
                display: none;
            }

            .list {
                display: block;
                position: relative;
                background-color: transparent;
                left: inherit;
                top: inherit;

                .option {
                    font-size: 2.25em;
                    padding: 0;
                    margin-left: 50px;
                    display: inline-block;
                    position: relative;
                    color: $fontColor;

                    &:nth-child(1) {
                        margin-left: 0;

                        &::after {
                            display: none;
                        }
                    }

                    &::after {
                        content: '/';
                        position: absolute;
                        top: 0;
                        left: -32px;
                    }

                    &.active {
                        display: inline-block;

                        &::before {
                            content: '';
                            position: absolute;
                            bottom: -4px;
                            left: 0;
                            width: 100%;
                            height: 3px;
                            background-color: $color1;
                        }
                    }
                }

                .option.date {
                    padding-right: 25px;
                }
            }

            &.suboptions {
                border-top: none;
                margin-top: 30px;
                background-color: transparent;

                .list {
                    .option {
                        font-size: 1.5em;

                        &::after {
                            display: none;
                        }
                    }
                }
            }
        }

        .cat-info {
            display: block;
            max-width: 720px;

            h1 {
                display: block;
                font-size: 3em;
                margin-top: 40px;
                margin-bottom: 15px;
            }

            margin-top: 0px;

            &.less-margin {
                margin-top: 0px;
            }

            &.no-margin-top {
                margin-top: 0px;
            }
        }

        .movies-holder {
            margin-top: 20px;

            &.less-margin {
                margin-top: 60px;
            }

            &.no-margin-top {
                margin-top: 60px;
            }

            .item {
                padding: 0;
                border: none;
                width: 340px;
                float: left;
                margin-left: 20px;
                margin-bottom: 50px;
                position: relative;
                overflow: hidden;

                h2 {
                    font-size: em(24);
                    margin-top: 10px;
                }

                h2 span {
                    margin-right: 0px;
                }

                &:nth-child(odd) {
                    margin-left: 0;
                    clear: both;
                }

                .img-holder {
                    display: block;
                    position: relative;
                    width: 100%;
                    height: 230px;
                    background-color: rgba(0, 0, 0, 0.01);

                    img {
                        position: absolute;
                        display: block;
                        width: 100%;
                        height: auto;
                    }

                    .overlay {
                        display: block;
                        position: absolute;
                        top: 0;
                        left: 0;
                        box-sizing: border-box;
                        width: 100%;
                        height: 100%;
                        padding: 23px;
                        background-color: rgba(231, 227, 218, 0.96);
                        z-index: 10;
                        line-height: 1.5;
                        transform: translateY(100%);
                        transition: transform 0.3s ease;

                        p {
                            font-size: 0.95em;
                            line-height: 1.25;
                            margin-bottom: 12px;
                            transform: translateY(30px);
                            opacity: 0;
                            transition:
                                transform 0.3s 0.2s ease,
                                opacity 0.3s 0.2s ease;
                        }

                        svg {
                            opacity: 0;
                            transform: translateY(30px);
                            transition:
                                transform 0.3s 0.2s ease,
                                opacity 0.3s 0.2s ease;
                        }
                    }
                }

                &.has-label .img-holder .overlay {
                    transition: transform 0.3s 0.2s ease;

                    p {
                        transition:
                            transform 0.3s 0.4s ease,
                            opacity 0.3s 0.4s ease;
                    }

                    svg {
                        transition:
                            transform 0.3s 0.4s ease,
                            opacity 0.3s 0.4s ease;
                    }
                }

                .labels {
                    position: absolute;
                    bottom: inherit;
                    top: 200px;
                    right: inherit;
                    left: 0px;
                    transform: translateX(0%);
                    transition: transform 0.3s 0.3s ease;

                    .label {
                        color: #fff;
                        font-weight: bold;
                        font-size: 0.65em;
                        text-transform: uppercase;
                        letter-spacing: 0.05em;
                        padding: 4px 8px;
                    }
                }
            }
        }

        .movies-holder--margin-top {
            margin-top: 55px;
        }
    }

    #movieoverviewpage.index {
        .options.suboptions .list .option {
            margin-left: 30px;
        }

        .options.suboptions .list .option:first-child {
            margin-left: 0px;
        }
    }

    .no-touchevents #movieoverviewpage .options .list .option:hover::before {
        content: '';
        position: absolute;
        bottom: -4px;
        left: 0;
        width: 100%;
        height: 3px;
        background-color: $color2;
    }

    .no-touchevents #movieoverviewpage .options .list .option.active:hover::before {
        background-color: $color1;
    }

    .no-touchevents #movieoverviewpage .movies-holder .item:hover .overlay,
    .no-touchevents #archivepage .movies-holder .item:hover .overlay {
        transform: translateY(0);
    }

    .no-touchevents #movieoverviewpage .movies-holder .item:hover .overlay p,
    .no-touchevents #archivepage .movies-holder .item:hover .overlay p,
    .no-touchevents #movieoverviewpage .movies-holder .item:hover .overlay svg,
    .no-touchevents #archivepage .movies-holder .item:hover .overlay svg {
        transform: translateY(0);
        opacity: 1;
    }

    .no-touchevents #movieoverviewpage .movies-holder .item:hover .labels,
    .no-touchevents #archivepage .movies-holder .item:hover .labels {
        transform: translateX(-100%);
        transition: transform 0.3s ease;
    }

    .no-touchevents #movieoverviewpage .movies-holder .item:hover .info,
    .no-touchevents #archivepage .movies-holder .item:hover .info {
        color: $color1;
    }

    .no-touchevents #movieoverviewpage a:hover .img-holder img,
    .no-touchevents #archivepage a:hover .img-holder img {
        transform: scale(1);
    }

    :lang(en) #movieoverviewpage .options .list .option.english {
        color: #34c4ad;
    }

    :lang(nl) #movieoverviewpage .options .list .option.english {
        color: rgba(59, 53, 48, 0.5);
    }
}

@media only screen and (min-width: 1024px) {
    #movieoverviewpage,
    #archivepage {
        .movies-holder {
            .item {
                width: 300px;
                margin-left: 20px;

                &:nth-child(odd) {
                    margin-left: 20px;
                    clear: none;
                }

                &:nth-child(3n + 1) {
                    margin-left: 0;
                    clear: both;
                }

                .labels {
                    top: 170px;

                    .label {
                        font-size: 0.875em;
                        padding: 4px 8px;
                    }
                }

                .img-holder {
                    height: 203px;
                }
            }
        }
    }

    #movieoverviewpage.index {
        .options.suboptions .list .option {
            margin-left: 30px;
        }

        .options.suboptions .list .option:first-child {
            margin-left: 0px;
        }
    }
}

@media only screen and (min-width: 1440px) {
    #movieoverviewpage,
    #archivepage {
        .movies-holder {
            .item {
                width: 280px;
                margin-left: 20px;

                &:nth-child(3n + 1) {
                    margin-left: 20px;
                    clear: none;
                }

                &:nth-child(4n + 1) {
                    margin-left: 0px;
                    clear: both;
                }

                .labels {
                    top: 160px;
                }

                .img-holder {
                    height: 190px;
                }

                .img-holder .overlay {
                    padding: 20px;

                    p {
                        line-height: 1.2;
                        margin-bottom: 7px;
                    }
                }
            }
        }
    }
}

.movies-view-toggle {
    display: none;

    @media only screen and (min-width: 768px) {
        display: block;
        margin-top: 30px;
    }
}

.movies-view-toggle__view {
    float: right;
    margin-right: 15px;
    fill: #d4d0c7;
}

.movies-view-toggle__view--active {
    fill: #9a968e;
}

.movies-overview-message-holder {
    margin-top: 30px;
    padding: 0 15px;

    @media only screen and (min-width: 768px) {
        padding: 0;
        margin-bottom: 20px;
    }

    @media only screen and (min-width: 1024px) {
        width: 700px;
        margin: 0 auto 20px;
    }
}

.movies-overview-message {
    padding: 24px 20px;
    background-color: #70c4d0;
    color: #fff;
    box-sizing: border-box;

    @media only screen and (min-width: 768px) {
        padding: 30px 40px 33px;
    }
}

.movies-overview-message__title {
    line-height: 1.1;
    margin-bottom: 5px;

    @media only screen and (min-width: 768px) {
        margin-bottom: 15px;
    }
}

.movies-overview-message__text {
    font-size: em(14px);
    margin-bottom: 0;
}
