#restaurantpage {
    .menu-card {
        background-color: #fff;
        padding-top: 0px;
        padding-bottom: 40px;
        position: relative;
        margin-top: 80px;
        margin-bottom: 50px;

        i.vega,
        em.vega {
            display: inline-block;
            height: 18px;
            width: 18px;
            overflow: hidden;
            text-indent: -99999em;
            background: url('/media/images/vega.svg') 0 0 no-repeat;
            margin-top: 0px;
            box-sizing: border-box;
            background-position: 0px 0px;
            transform: translateY(3px);
        }

        i.vegan,
        em.vegan {
            display: inline-block;
            height: 18px;
            width: 18px;
            overflow: hidden;
            text-indent: -99999em;
            background: url('/media/images/vegan.svg') 0 0 no-repeat;
            margin-top: 0px;
            box-sizing: border-box;
            background-position: 0px 0px;
            transform: translateY(3px);
        }

        i.lactose,
        em.lactose {
            display: inline-block;
            height: 18px;
            width: 18px;
            overflow: hidden;
            text-indent: -99999em;
            background: url('/media/images/lactose.svg') 0 0 no-repeat;
            margin-top: 0px;
            box-sizing: border-box;
            background-position: 0px 0px;
            transform: translateY(3px);
        }

        i.zeevruchten,
        em.zeevruchten {
            display: inline-block;
            height: 18px;
            width: 18px;
            overflow: hidden;
            text-indent: -99999em;
            background: url('/media/images/zeevruchten.svg') 0 0 no-repeat;
            margin-top: 0px;
            box-sizing: border-box;
            background-position: 0px 0px;
            transform: translateY(3px);
        }

        .label {
            display: inline-block;
            position: absolute;
            top: 0;
            left: 20px;
            transform: translateY(-100%);
            background-color: $color4;
            text-transform: uppercase;
            color: #fff;
            font-weight: bold;
            font-size: 0.875em;
            padding: 10px 15px;
        }

        p {
            font-size: 0.875em;
            line-height: 1.5em;

            &.small-mb {
                margin-bottom: 10px;
            }
        }

        .options {
            background-color: #e7e3da;
            position: relative;
            transform: translateX(-20px);
            padding: 0 20px;
            width: 100%;
            z-index: 3;

            .current {
                font-size: 1.625em;
                font-weight: bold;
                cursor: pointer;
                padding: 20px 0;

                svg {
                    position: absolute;
                    top: 30px;
                    right: 30px;
                    width: 12px;
                    height: 8px;
                }
            }

            .list {
                display: none;
                position: absolute;
                background-color: #e7e3da;
                left: 0;
                top: 66px;
                width: 100%;
                z-index: 2;

                &.active {
                    display: block;
                }

                .option {
                    font-size: 1.625em;
                    font-weight: bold;
                    cursor: pointer;
                    padding: 0px 0 20px 35px;

                    &.active {
                        display: none;
                    }
                }
            }

            &.suboptions {
                margin-top: 1px;
                z-index: 2;

                .current {
                    font-size: 1em;
                    padding: 25px 0;
                }

                .list {
                    .option {
                        font-size: 1em;
                    }
                }
            }
        }

        .info,
        .information {
            margin-top: 15px;
            margin-bottom: 25px;
        }

        .card {
            display: none;

            &.active {
                display: block;
            }

            h2 {
                font-size: 1.125em;
                margin-bottom: 10px;
                line-height: normal;

                &.no-mb {
                    margin-bottom: 0;
                }

                &.large-mb {
                    margin-bottom: 15px;
                }
            }

            h3 {
                font-size: 1em;
                margin-bottom: 12px;
                line-height: normal;
            }

            ul {
                margin: 0 0 25px;
                padding: 0;

                li {
                    padding-right: 85px;
                    display: block;
                    box-sizing: border-box;
                    position: relative;
                    list-style: none;
                    margin-bottom: 15px;

                    &:last-of-type {
                        margin-bottom: 0;
                    }

                    &::before {
                        content: '\2022 ';
                        position: relative;
                        font-size: 1em;
                        margin-right: 4px;
                    }

                    span.price {
                        position: absolute;
                        top: 0;
                        right: 0;
                    }
                }
            }

            .item {
                padding-right: 85px;
                display: block;
                box-sizing: border-box;
                position: relative;
                margin-bottom: 15px;

                span.name {
                    display: block;
                    font-weight: bold;
                }

                span.price {
                    display: block;
                }
            }
        }
    }
}

@media only screen and (min-width: 768px) {
    #restaurantpage {
        .menu-card {
            padding-bottom: 60px;

            &::before {
                content: '';
                position: absolute;
                top: 0;
                right: 0;
                height: 100%;
                width: 100vw;
                background-color: #fff;
            }

            .inner {
                position: relative;
                z-index: 1;
            }

            .label {
                left: 0px;
                font-size: 1.125em;
                padding: 10px 15px;
            }

            p {
                font-size: 1em;
            }

            .card {
                h2 {
                    font-size: 1.5em;
                }

                h3 {
                    font-size: 1.2em;
                }

                ul {
                    li {
                        font-size: 1em;
                        line-height: normal;
                    }
                }
            }
        }
    }
}

@media only screen and (min-width: 1024px) {
    #restaurantpage {
        .menu-card {
            padding-top: 70px;

            .options {
                background-color: transparent;
                transform: translateX(0px);
                padding: 0;
                width: 100%;
                margin-bottom: 15px;

                .current {
                    display: none;
                }

                .list {
                    display: block;
                    position: relative;
                    background-color: transparent;
                    left: inherit;
                    top: inherit;

                    .option {
                        font-size: 2.25em;
                        padding: 0;
                        margin-left: 50px;
                        display: inline-block;
                        position: relative;

                        &:nth-child(1) {
                            margin-left: 0;

                            &::after {
                                display: none;
                            }
                        }

                        &::after {
                            content: '/';
                            position: absolute;
                            top: 0;
                            left: -32px;
                        }

                        &.active {
                            display: inline-block;

                            &::before {
                                content: '';
                                position: absolute;
                                bottom: -4px;
                                left: 0;
                                width: 100%;
                                height: 3px;
                                background-color: $color4;
                            }
                        }
                    }
                }

                &.suboptions {
                    margin-top: 30px;

                    .list {
                        .option {
                            font-size: 1.5em;

                            &::after {
                                display: none;
                            }
                        }
                    }
                }
            }

            .card {
                .info,
                .information {
                    margin-top: 40px;
                }

                ul {
                    li {
                        padding-right: 150px;
                    }
                }

                .item {
                    padding-right: 150px;

                    span.price {
                        position: absolute;
                        top: 0;
                        right: 0;
                    }
                }
            }
        }
    }
}

@media only screen and (min-width: 1440px) {
    #restaurantpage {
        .menu-card {
            &::before {
                display: none;
            }

            .label {
                left: 60px;
            }

            .card {
                ul {
                    li {
                        padding-right: 205px;
                    }
                }
            }
        }
    }
}
