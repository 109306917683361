#moviefestivalpage {
    .festival-list {
        .item {
            margin-bottom: 25px;

            .trailer-holder .plyr .plyr__control--overlaid[data-plyr='play'] {
                display: none !important;
            }

            &.active {
                .content {
                    opacity: 1;
                    transform: scaleY(1);
                    height: auto;
                }

                .title {
                    .img-container {
                        height: auto;

                        &.no-trailer {
                            padding-bottom: 56.25%;
                        }
                    }

                    .close-btn {
                        transform: translateY(0);
                    }

                    .top-space {
                        height: 20px;
                        background-color: #fff;
                        transition: height 0.6s ease, background-color 0.3s 0.3s ease;
                    }

                    .event-header-holder {
                        background-color: #fff;
                        transition: background-color 0.3s 0.3s ease;

                        svg {
                            transform: rotate(180deg);
                            top: 8px;
                        }
                    }

                    span.info {
                        display: none;
                    }
                }
            }

            .title {
                .img-container {
                    height: 100px;
                    position: relative;
                    overflow: hidden;
                    transition: height 0.6s ease;
                    cursor: pointer;

                    img {
                        display: block;
                        width: 100%;
                        height: 100%;
                        position: absolute;
                        object-fit: cover;
                    }

                    .close-btn {
                        position: absolute;
                        right: 0;
                        top: 0;
                        text-indent: -99999em;
                        overflow: hidden;
                        transform: translateY(-100%);
                        transition: transform 0.6s ease;
                    }
                }

                .top-space {
                    height: 15px;
                    transition: height 0.6s ease, background-color 0.3s 0s ease;
                }

                .event-header-holder {
                    position: relative;
                    transition: all 0.3s 0s ease;
                    cursor: pointer;

                    svg {
                        width: 12px;
                        height: 7px;
                        fill: $color1;
                        position: absolute;
                        left: 5px;
                        top: 10px;
                        transform: rotate(0deg);
                    }

                    h3 {
                        font-size: 1.375em;
                        padding-left: 20px;
                        margin-bottom: 0;
                        position: relative;
                    }
                }

                span.info {
                    display: block;
                    padding-left: 20px;
                    text-transform: lowercase;
                    font-weight: bold;
                    color: #9a968e;
                    font-size: 1em;
                    cursor: pointer;
                }
            }

            .content {
                opacity: 0;
                transform: scaleY(0);
                height: 0;
                transition: transform 0.3s ease, opacity 0.3s 0.3s ease;
                transform-origin: 0 0;
                margin-top: 0px;
                margin-bottom: 25px;
                overflow: hidden;

                .movie-intro {
                    margin-top: 0;

                    p:last-of-type {
                        margin-bottom: 10px;
                    }
                }

                .kijkwijzer {
                    margin-bottom: 25px;
                }

                .festival-movie-ticket {
                    width: 270px;
                    margin-top: 30px;

                    .time-tickets-holder {
                        .time-tickets {
                            padding: 0;
                            background-color: transparent;

                            h4 {
                                display: none;
                            }

                            .item {
                                border: none;
                                margin-bottom: 0px;

                                .time-slots {
                                    a {
                                        text-decoration: none;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@media only screen and (min-width: 580px) {
    #moviefestivalpage .festival-list .item .title .img-container {
        height: 120px;

        img {
            position: absolute;
            top: 50%;
            left: 0;
            transform: translateY(-50%);
        }
    }
}

#moviefestivalpage #sidebar .schedule-holder {
    display: block;
}

#moviefestivalpage #sidebar .schedule-holder .schedule {
    padding: 18px 15px 0;
}

@media only screen and (min-width: 768px) {
    #moviefestivalpage {
        .festival-list .item {
            &.active {
                .content {
                    opacity: 1;
                    transform: scaleY(1);
                    height: auto;

                    h1 {
                        font-size: 2.25em;
                    }

                    .festival-movie-ticket {
                        margin-top: 75px;

                        .time-tickets-holder {
                            .time-tickets {
                                h4 {
                                    display: block;
                                    font-size: 1.5em;
                                    margin-bottom: 20px;
                                }
                            }
                        }
                    }
                }
            }
        }

        #sidebar .schedule-holder {
            margin-top: -50px;
            position: relative;

            .schedule {
                padding-top: 18px;
                padding-bottom: 10px;

                .item:nth-last-of-type(1) {
                    border-bottom: none;
                }
            }
        }

        h2.programme-title {
            margin-top: 90px;
            font-size: 3em;
        }
    }

    // TODO: Fix the stylelint concern here, and remove this comment.
    /* stylelint-disable-next-line no-descending-specificity, no-duplicate-selectors */
    #moviefestivalpage .festival-list .item {
        .title {
            .img-container {
                height: 100px;
            }
        }

        // TODO: Fix the stylelint concern here, and remove this comment.
        /* stylelint-disable-next-line no-duplicate-selectors */
        &.active {
            .title {
                .img-container {
                    height: 178px;

                    &.no-trailer {
                        padding-bottom: 0;
                    }
                }
            }
        }

        .movie-intro {
            padding-top: 18px;
        }

        .movie-intro p {
            font-size: 1.125em;
            line-height: 1.5;
            margin-bottom: 15px;
        }
    }
}

@media only screen and (min-width: 1024px) {
    #moviefestivalpage .festival-list .item {
        .content h1 {
            font-size: 3.5em;
        }

        .title {
            .img-container {
                height: 200px;
            }

            span.info {
                font-size: 1.125em;
                padding-left: 30px;
                padding-top: 5px;
            }

            .event-header-holder {
                h3 {
                    font-size: 2.25em;
                    padding-left: 30px;
                }

                svg {
                    width: 12px;
                    height: 7px;
                    fill: $color1;
                    position: absolute;
                    left: 10px;
                    top: 15px;
                }
            }
        }

        &.active {
            .title {
                .img-container {
                    height: 290px;
                }

                .notice {
                    margin-bottom: 35px;
                }

                .event-header-holder {
                    svg {
                        top: 15px;
                    }
                }

                .top-space {
                    height: 42px;
                }
            }
        }
    }
}

@media only screen and (min-width: 1440px) {
    #moviefestivalpage .festival-list .item {
        .title {
            span.info {
                padding-left: 60px;
            }

            .event-header-holder {
                h3 {
                    padding-left: 60px;
                }

                svg {
                    width: 19px;
                    height: 13px;
                    fill: $color1;
                    position: absolute;
                    left: 22px;
                    top: 15px;
                }
            }
        }

        &.active {
            .title {
                .img-container {
                    height: 290px;
                }

                .content {
                    .notice {
                        margin-bottom: 35px;
                    }

                    .event-header-holder {
                        svg {
                            top: 15px;
                        }
                    }
                }
            }
        }
    }

    // TODO: Fix the stylelint concern here, and remove this comment.
    /* stylelint-disable-next-line no-duplicate-selectors */
    #moviefestivalpage .festival-list .item.active .title .img-container {
        height: 365px;
    }
}
