.press-register {
    #sidebar .cta.pressletter {
        display: none;
    }

    #press-form-holder {
        background-color: #fff;
        padding: 20px;
    }

    form {
        position: relative;
        padding-top: 10px;

        input[type='text'] {
            border: none;
            border-bottom: 2px solid $color2;
            width: 100%;
            padding: 0;
            margin: 0;
            box-sizing: border-box;
            height: 30px;
            padding-left: 5px;
            font-size: 0.84375em;
            font-family: CalibreWeb;
            margin-bottom: 13px;

            &:focus {
                border-bottom: 2px solid $color4;
            }
        }

        input[type='text'].error {
            border-bottom: 2px solid $red;
        }

        input[type='submit'] {
            border: none;
            background-color: #fff;
            text-transform: uppercase;
            cursor: pointer;
            padding: 5px 13px 5px 0px;
            font-family: CalibreWeb;
            font-size: 1.125em;
            font-weight: bold;
        }
    }
}

.press-form-success {
    display: block;
    background-color: $green;
    padding: 15px;
    color: white;
    margin-bottom: 25px;
}

.press-form-error {
    display: block;
    background-color: $red;
    padding: 15px;
    color: white;
    margin-bottom: 25px;
}

@media only screen and (min-width: 768px) {
    .press-register {
        form {
            position: relative;

            input[type='text'] {
                font-size: 1.125em;
            }
        }
    }
}
