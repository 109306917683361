#footer {
    height: calc(401px + 87px); //  + logo & margin
    margin-top: 60px;
    box-sizing: border-box;
    position: relative;

    .newsletter-holder {
        position: relative;
        background-color: #fff;
        margin: 0 auto -25px;
        z-index: 2;
        padding: 20px 20px 15px 20px;
        width: 290px;
        box-sizing: border-box;

        h4 {
            font-weight: bold;
            font-size: 1.4em;
            margin-bottom: 20px;
        }

        p {
            color: rgba(59, 53, 48, 0.5);
            font-size: 0.75em;
            font-family: Tiempo, sans-serif;
            line-height: 1.5;
        }

        form {
            position: relative;

            .success,
            .error {
                display: none;
            }
        }

        form.submitting {
            opacity: 0.25;
            pointer-events: none;

            .error-indicator {
                display: none;
            }
        }

        form.success input,
        form.error input,
        form.success .checkbox-holder,
        form.error .checkbox-holder,
        form.success .newsletter-privacy-link,
        form.error .newsletter-privacy-link {
            display: none;
        }

        form.error .error {
            display: block;
        }

        form.success .success {
            display: block;
        }

        form .error-indicator {
            display: block;
            margin-top: 5px;
            color: $red;
            font-size: em(12);
        }

        input[type='text'] {
            border: none;
            border-bottom: 2px solid $color2;
            width: 100%;
            padding: 0;
            margin: 0;
            box-sizing: border-box;
            height: 30px;
            padding-left: 5px;
            font-size: 0.8438em;
            font-family: CalibreWeb, sans-serif;
            border-radius: 0;

            &:focus {
                border-bottom: 2px solid $color4;
            }
        }

        input[type='submit'] {
            position: absolute;
            top: 0;
            right: 0;
            border: none;
            background-color: #fff;
            text-transform: uppercase;
            cursor: pointer;
            padding: 5px 0 5px 13px;
            font-family: CalibreWeb, sans-serif;
            font-size: 1.125em;
            font-weight: bold;
            border-radius: 0;

            &:focus {
                color: $color1;
            }
        }

        .newsletter-checkbox {
            display: none;
        }

        .checkbox-block {
            margin-top: 20px;
            display: flex;
            align-items: center;
        }

        .checkbox-replace {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: 10px;
            width: 20px;
            height: 20px;
            border: 1px solid rgba(59, 53, 48, 0.3);
        }

        .checkbox-check {
            display: block;
            width: 16px;
        }

        .newsletter-chekbox-text {
            flex: 1;
            font-size: em(14px);
            color: rgba(59, 53, 48, 0.5);
        }

        .newsletter-privacy-link {
            display: block;
            margin-top: 15px;
            font-size: em(13px);
            color: rgba(59, 53, 48, 0.5);
            text-decoration: underline;
        }
    }

    .inner {
        height: 100%;
        position: relative;
        background-color: $color4;

        .left {
            display: none;
        }

        .right {
            padding-top: 65px;
            width: 250px;
            margin: 0 auto;
        }

        .contact-block {
            margin-bottom: 37px;

            h4 {
                font-size: em(14px);
                color: #fff;
                font-family: CalibreWeb, sans-serif;
                font-weight: bold;
                text-transform: uppercase;
                margin-bottom: 10px;
            }

            p {
                color: #fff;
                font-size: em(14px);
                line-height: 1.3;
            }

            a {
                color: #fff;
            }
        }

        .social {
            width: 250px;
            margin: 0 auto;
            margin-bottom: 37px;

            h4 {
                font-size: em(14px);
                color: #fff;
                font-family: CalibreWeb, sans-serif;
                font-weight: bold;
                text-transform: uppercase;
                margin-bottom: 10px;
            }

            .facebook {
                float: left;
                display: inline-block;
                text-indent: -999999em;
                overflow: hidden;
                width: 14px;
                height: 29px;
                background: url('/media/images/icn-facebook.svg') 0 0 no-repeat;
                background-size: 14px 29px;
                margin-left: 10px;
            }

            .instagram {
                float: left;
                display: inline-block;
                text-indent: -999999em;
                overflow: hidden;
                width: 30px;
                height: 30px;
                background: url('/media/images/icn-instagram.svg') 0 0 no-repeat;
                background-size: 30px 30px;
                margin-right: 10px;
            }
        }

        .europa-cinemas {
            img {
                width: 125px;
                height: auto;
            }
        }

        .logo-mobile {
            width: 146px;
            height: 62px;
            margin: 25px auto;
            background:
                url('/media/images/logo-footer.svg') 0 0
                no-repeat;
            background-size: 146px 62px;
        }

        .copyright {
            position: absolute;
            bottom: 15px;
            left: 0;
            width: 100%;
            text-align: center;
            color: #fff;
            line-height: 1.2;
            font-size: em(11px);

            a {
                color: #fff;
            }

            .links-holder {
                margin-top: 5px;
            }
        }
    }
}

@media only screen and (min-width: 590px) {
    #footer {
        height: calc(560px + 105px); //  + logo & margin
        margin-top: 115px;

        .newsletter-holder {
            width: 550px;

            h4 {
                font-size: em(45px);
            }

            p {
                font-size: em(18px);
            }

            input[type='text'] {
                font-size: em(19px);
            }
        }

        .inner {
            .right {
                padding-top: 135px;
                width: 476px;
            }

            .contact-block {
                display: inline-block;
                float: left;
                width: 270px;
                margin-bottom: 0;
                margin-right: 66px;

                h4 {
                    font-size: em(20px);
                    margin-bottom: 17px;
                }

                p {
                    font-size: em(16px);
                    line-height: 1.4;
                }
            }

            .social {
                width: 130px;
                margin: 0;
                float: left;

                h4 {
                    font-size: em(20px);
                    margin-bottom: 17px;
                }

                .facebook {
                    width: 16px;
                    height: 33px;
                    background-size: 16px 33px;
                    margin-left: 20px;
                }

                .instagram {
                    width: 35px;
                    height: 35px;
                    background-size: 35px 35px;
                    margin-right: 20px;
                }
            }

            .europa-cinemas {
                clear: both;
                float: left;
                margin-top: 55px;
                display: flex;
                justify-content: center;
                width: 100%;
            }

            .logo-mobile {
                clear: both;
                width: 292px;
                height: 78px;
                margin: 70px auto 0;
                background-size: 292px 78px;
            }

            .copyright {
                bottom: 20px;
                font-size: em(17px);
            }
        }
    }
}

@media only screen and (min-width: 768px) {
    #footer {
        height: calc(520px + 105px); //  + logo & margin

        .newsletter-holder {
            margin-bottom: -50px;
            padding: 30px 105px 20px 40px;
            width: 700px;

            h4 {
                font-size: 1.875em;
                width: 180px;
                float: left;
                margin-right: 10px;
                padding-right: 20px;
                box-sizing: border-box;
            }

            p {
                font-size: 1.125em;
                width: 338px;
                float: left;
                margin-top: 4px;
                margin-bottom: 13px;
            }

            form {
                float: left;
                width: 338px;
            }

            form .error-indicator {
                font-size: em(14);
            }

            input[type='text'] {
                font-size: 1.125em;
            }

            input[type='submit'] {
                padding: 0px 0 6px 13px;
                font-size: 1.5em;
            }
        }

        .inner {
            .holder {
                width: 580px;
                margin: 0 auto;
                padding-top: 115px;
            }

            .left {
                float: left;
                width: 290px;
                height: 290px;
                border-right: 1px solid #eed8a3;
                display: inline-block;
            }

            .logo {
                text-indent: -999999em;
                overflow: hidden;
                background: url('/media/images/logo-footer.svg') 0 0 no-repeat;
                width: 193px;
                height: 82px;
                background-size: 193px 82px;
                margin-left: 20px;
                margin-top: 67px;
            }

            .right {
                float: left;
                padding-top: 0;
                width: 209px;
                margin-left: 80px;
                margin-top: 30px;
            }

            .contact-block {
                display: block;
                float: none;
                width: auto;
                margin-bottom: 55px;
                margin-right: 0;

                h4 {
                    font-size: em(14px);
                }

                p {
                    font-size: em(16px);
                }
            }

            .social {
                width: auto;
                margin: 0;
                float: none;

                h4 {
                    font-size: em(14px);
                }

                .facebook {
                    width: 9px;
                    height: 18px;
                    background-size: 9px 18px;
                    margin-left: 10px;
                }

                .instagram {
                    width: 18px;
                    height: 18px;
                    background-size: 18px 18px;
                    margin-right: 10px;
                }
            }

            .europa-cinemas {
                display: block;
                width: auto;
                float: none;

                img {
                    width: 150px;
                    height: auto;
                }
            }

            .logo-mobile {
                display: none;
            }

            .copyright {
                bottom: 15px;
                font-size: em(14px);
            }
        }
    }
}
