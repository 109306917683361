#homepage #sidebar .cta-kids {
    display: none;
}

#homepage #sidebar .schedule-holder {
    display: block;
}

#sidebar {
    max-width: 550px;
    margin: 100px auto 0;

    .schedule-holder {
        display: none;
    }

    .cta {
        margin-bottom: 25px;
        display: block;
        transition: background-color 0.3s ease, color 0.3s ease;

        &.cta-link {
            &:hover {
                background-color: #34c4ad;
                color: #fff;
            }
        }

        &.cta-card--desktop {
            display: none;
        }

        &.cta--no-text .txt {
            padding: 10px 15px 15px;

            h4 {
                font-family: CalibreWeb, sans-serif;
                font-weight: bold;
                color: #3b3530;
                font-size: 1.25em;
                margin-bottom: 0;
            }
        }

        &.cta--gold {
            .txt {
                background-color: $color4;
                color: $fontColor;
            }
        }

        .txt {
            padding: 30px 20px 20px;
            line-height: 1.5;

            h4 {
                font-size: 1.875em;
                font-weight: bold;
                margin-bottom: 20px;
                line-height: 1;
            }

            p {
                line-height: 1.5;
                margin-bottom: 18px;

                &:last-child {
                    margin-bottom: 0;
                }
            }

            ul {
                line-height: 1.5;
            }

            a {
                text-decoration: underline;
            }
        }

        &.color {
            background-color: $color4;
            color: $fontColor;

            a {
                color: $fontColor;
            }
        }

        &.color.date {
            span.date {
                display: block;
                font-weight: bold;
                font-size: 1.375em;
            }
        }

        &.photo {
            background-color: #fff;

            img {
                display: block;
                width: 100%;
                height: auto;
            }

            &.transparent {
                background-color: transparent;
            }
        }

        &.movie {
            background-color: #fff;

            .img-holder {
                position: relative;

                img {
                    display: block;
                    width: 100%;
                    height: auto;
                }

                .labels {
                    position: absolute;
                    bottom: 10px;
                    left: 0;
                }
            }

            span.date {
                display: block;
                font-weight: bold;
                font-size: 1.375em;
            }
        }
    }

    .also-as-special {
        background-color: #fff;
        display: block;
        padding-top: 18px;

        h3 {
            font-weight: bold;
            font-size: 1.25em;
            margin-bottom: 5px;
        }

        .item {
            border-top: 1px solid #ebeae8;
            border-bottom: none;

            &:nth-child(2) {
                border-top: none;
            }

            .info {
                background-color: $color2;
                transition: background-color 0.3s ease;
            }
        }
    }

    .quote-holder {
        display: none;
    }
}

.no-touchevents #sidebar .also-as-special .item:hover .info {
    background-color: $color4;
}

#organizepage #sidebar,
#infopage #sidebar,
#presspage #sidebar {
    width: auto;
    padding: 0 15px;
}

.cta-card {
    margin-left: 15px;
    margin-right: 15px;
    background-color: #fff;

    img {
        width: 100%;
        height: auto;
        display: block;
    }

    &.cta-card--mobile {
        display: block;
        margin-left: 0px;
        margin-right: 0px;
    }

    &.cta-card--lab {
        margin-top: 40px;

        .title {
            background-color: $color4;
            padding: 10px 15px 15px;

            h4 {
                font-family: CalibreWeb;
                font-weight: bold;
                color: $fontColor;
                font-size: 1.25em;
            }
        }
    }

    &.cta-card--desktop {
        display: none;
    }
}

@media only screen and (max-width: 767px) {
    #sidebar .cta--hide-mobile {
        display: none;
    }
}

@media only screen and (min-width: 550px) {
    .cta-card {
        margin-left: 0px;
        margin-right: 0px;
    }
}

@media only screen and (min-width: 768px) {
    #homepage #sidebar .cta-kids {
        display: block;
    }

    #sidebar {
        margin-top: 0;

        .schedule-holder {
            display: block;
        }

        .also-as-special {
            padding-top: 15px;

            h3 {
                font-size: 1.5em;
            }
        }

        .quote-holder {
            padding: 0 20px;
            display: block;

            .quote {
                font-size: 1.875em;
                line-height: 1.2;
                font-style: italic;
                font-family: Tiempo;
                margin-bottom: 20px;
            }

            .quote-by {
                color: $color3;
                font-size: 1.125em;
            }
        }
    }

    #organizepage #sidebar,
    #infopage #sidebar,
    #presspage #sidebar {
        width: 300px;
        padding: 0;
    }

    .cta {
        margin-bottom: 40px;

        .txt {
            padding: 30px 30px 20px;

            h4 {
                font-size: 2.25em;
            }

            p {
                font-size: 1em;
            }
        }

        &.cta-kids,
        &.cta-card {
            img {
                width: 100%;
                height: auto;
                display: block;
            }

            .title {
                background-color: $color4;
                padding: 15px 30px 20px;

                h4 {
                    font-family: CalibreWeb;
                    font-weight: bold;
                    color: $fontColor;
                    font-size: 2.25em;
                }
            }
        }

        &.cta-card {
            .title {
                background-color: #fff;

                h4 {
                    color: $fontColor;
                }
            }
        }

        &.cta-card--lab {
            .title {
                background-color: $color4;

                h4 {
                    color: $fontColor;
                    font-size: 2.2em;
                }
            }
        }
    }

    .cta-card.cta-card--mobile {
        display: none;
    }

    /* stylelint-disable-next-line no-duplicate-selectors */
    #sidebar {
        .cta {
            &.cta--no-text {
                .txt {
                    padding: 15px 30px 20px;

                    h4 {
                        font-family: CalibreWeb;
                        font-weight: bold;
                        font-size: 2.25em;
                        margin-bottom: 0;
                    }
                }
            }
        }
    }

    #sidebar .cta-card.cta-card--desktop,
    .cta-card.cta-card--desktop {
        display: block;
    }
}

@media only screen and (min-width: 1440px) {
    #sidebar {
        .also-as-special {
            h3 {
                font-size: 1.875em;
            }
        }
    }

    #organizepage #sidebar,
    #infopage #sidebar,
    #presspage #sidebar {
        width: 360px;
        padding: 0;
    }
}
