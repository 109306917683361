#moviepage,
#movieseriespage,
#movieeventpage,
#moviefestivalpage {
    .movie-intro {
        background-color: #fff;
        padding-top: 20px;
        padding-bottom: 20px;
        position: relative;
        margin-top: -52px;
        margin-bottom: 30px;

        h1 {
            color: $fontColor;
            font-size: 2.25em;
            margin-bottom: 19px;
        }

        p {
            font-family: Tiempo;
            font-size: 1.1em;
            line-height: 1.3;
        }

        .notice {
            margin-bottom: 20px;

            .inner {
                border: 2px solid $color3;
                color: $color3;
                font-weight: bold;
                font-size: 0.75em;
                padding: 8px 10px 10px;
                display: inline-block;
                clear: both;
                margin-bottom: 15px;
            }
        }

        .labels {
            position: absolute;
            top: -50px;
            left: 0;

            &.no-label {
                top: -30px;
            }

            span.label {
                font-size: 0.67em;
                letter-spacing: 0.05em;
                font-weight: bold;
                padding: 6px 7px;
                // background-color: #3b3530;
                color: #fff;
                text-transform: uppercase;
                margin-right: 5px;
            }

            .breadcrumb {
                clear: both;
                display: block;
                width: 100%;
                color: #fff;
                font-weight: bold;
                padding-top: 4px;
                font-size: em(12px);
                // white-space: nowrap;

                a {
                    font-weight: bold;
                    margin-right: 2px;
                    display: inline-block;
                    color: #fff;
                    padding-right: 10px;
                    // background: url('/media/images/arrow-breadcrumb.svg') no-repeat right 4px;
                    background-size: 5px 7px;
                    text-transform: capitalize;

                    svg {
                        position: absolute;
                        right: 0px;
                        width: 5px;
                        height: 7px;
                        top: 4px;
                        fill: #fff;
                    }
                }
            }
        }

        .icons {
            float: left;
            // width: 115px;
            // width: 60px;
            width: 40px;
            position: relative;
            display: inline-block;
            padding-top: 14px;

            svg {
                fill: #d5d0c7;
                float: left;
                display: inline-block;
                cursor: pointer;

                &.seen {
                    width: 19px;
                    height: 15px;
                    padding-top: 1px;
                }

                &.wishlist {
                    width: 19px;
                    height: 17px;
                    margin: 0 20px;
                }

                &.share {
                    width: 19px;
                    height: 17px;
                }
            }
        }

        a.move-down {
            background-color: $color3;
            color: #fff;
            float: right;
            text-transform: lowercase;
            font-weight: bold;
            padding: 13px 7px;
            text-decoration: none;
        }
    }

    .movie-description {
        p,
        ul,
        ol {
            font-size: 1em;
            line-height: 1.5;
        }
    }

    .movie-info {
        color: $color2;
        font-size: 0.875em;
        margin-bottom: 20px;
        line-height: 1.25;

        br {
            display: none;
        }

        span {
            font-weight: bold;
        }
    }

    .kijkwijzer {
        .item {
            width: 27px;
            height: 27px;
            background-size: contain;
            background-position: 0 0;
            background-repeat: no-repeat;
            border-radius: 50%;
            float: left;
            display: block;
            margin-right: 10px;
            margin-bottom: 10px;

            &.kijkwijzer-6 {
                background-image: url('/media/images/kijkwijzer/kijkwijzer-6.svg');
            }

            &.kijkwijzer-9 {
                background-image: url('/media/images/kijkwijzer/kijkwijzer-9.svg');
            }

            &.kijkwijzer-12 {
                background-image: url('/media/images/kijkwijzer/kijkwijzer-12.svg');
            }

            &.kijkwijzer-14 {
                background-image: url('/media/images/kijkwijzer/kijkwijzer-14.png');
            }

            &.kijkwijzer-16 {
                background-image: url('/media/images/kijkwijzer/kijkwijzer-16.svg');
            }

            &.kijkwijzer-18 {
                background-image: url('/media/images/kijkwijzer/kijkwijzer-18.png');
            }

            &.kijkwijzer-al {
                background-image: url('/media/images/kijkwijzer/kijkwijzer-al.svg');
            }

            &.kijkwijzer-discriminatie {
                background-image: url('/media/images/kijkwijzer/kijkwijzer-discriminatie.svg');
            }

            &.kijkwijzer-sex {
                background-image: url('/media/images/kijkwijzer/kijkwijzer-seks.svg');
            }

            &.kijkwijzer-drugs {
                background-image: url('/media/images/kijkwijzer/kijkwijzer-drugs.svg');
            }

            &.kijkwijzer-geweld {
                background-image: url('/media/images/kijkwijzer/kijkwijzer-geweld.svg');
            }

            &.kijkwijzer-groftaalgebruik {
                background-image: url('/media/images/kijkwijzer/kijkwijzer-groftaalgebruik.svg');
            }

            &.kijkwijzer-angst {
                background-image: url('/media/images/kijkwijzer/kijkwijzer-angst.svg');
            }
        }
    }

    .doorlezer {
        margin-top: 50px;
    }

    .doorlezer,
    .programme {
        background-color: #fff;
        padding-top: 40px;
        padding-bottom: 40px;
        position: relative;

        .label {
            display: inline-block;
            position: absolute;
            top: 0;
            left: 20px;
            transform: translateY(-100%);
            background-color: $color4;
            text-transform: uppercase;
            color: #fff;
            font-weight: bold;
            font-size: 0.875em;
            padding: 10px 15px;
        }

        p {
            font-size: 0.875em;
            line-height: 1.5em;
        }

        a {
            color: $color2;
            text-decoration: underline;
        }

        img {
            width: 100%;
            max-width: 250px;
        }
    }

    .poster {
        display: block;
        margin-top: 50px;
        padding: 0 20px;
        max-width: 250px;
    }
}

@media only screen and (min-width: 768px) {
    #moviepage,
    #movieseriespage,
    #movieeventpage,
    #moviefestivalpage {
        .movie-intro {
            padding-top: 25px;
            padding-bottom: 30px;
            margin-top: -69px;
            margin-bottom: 35px;

            h1 {
                font-size: 3.5em;
                margin-bottom: 25px;
                line-height: 0.9;
            }

            p {
                font-size: em(22px);
                line-height: 1.5;
            }

            .notice {
                margin-bottom: 30px;

                .inner {
                    font-size: 0.92em;
                    padding: 8px 10px 10px;
                    margin-bottom: 0px;
                }
            }

            .labels {
                span.label {
                    font-size: 0.8em;
                    padding: 6px 7px;
                }

                .breadcrumb {
                    clear: none;
                    margin-left: 5px;
                    display: inline;
                    font-size: 1em;
                    padding-top: 0px;
                    white-space: nowrap;

                    span {
                        display: inline-block;
                        transform: translateY(4px);
                    }

                    a {
                        transform: translateY(4px);
                        margin-right: 4px;
                        padding-right: 13px;
                        background-size: 6px 9px;

                        svg {
                            width: 6px;
                            height: 9px;
                            top: 5px;
                        }
                    }
                }
            }

            a.move-down {
                display: none;
            }
        }

        .movie-description {
            margin-bottom: 25px;

            p,
            ol,
            ul {
                font-size: 1.125em;
                margin-bottom: 20px;
            }
        }

        .movie-info {
            font-size: 1.125em;
            margin-bottom: 30px;
        }

        .kijkwijzer {
            // margin-bottom: 120px;
        }

        .doorlezer {
            margin-top: 90px;
        }

        .doorlezer,
        .programme {
            padding-top: 50px;
            padding-bottom: 60px;

            &::before {
                content: '';
                position: absolute;
                top: 0;
                right: 0;
                height: 100%;
                width: 100vw;
                background-color: #fff;
            }

            .inner {
                position: relative;
                z-index: 1;
            }

            .label {
                left: 0px;
                font-size: 1.125em;
                padding: 10px 15px;
            }

            p {
                font-size: 1.125em;
            }

            img {
                max-width: 200px;
            }
        }

        .poster {
            max-width: 200px;
            margin-top: 60px;
        }
    }
}

@media only screen and (min-width: 1024px) {
    #moviepage,
    #movieseriespage,
    #movieeventpage,
    #moviefestivalpage {
        .movie-intro {
            padding-top: 34px;
            margin-top: -78px;
            padding-bottom: 15px;
            margin-bottom: 40px;

            h1 {
                padding-right: 90px;
            }

            .icons {
                float: none;
                position: absolute;
                padding-top: 0;
                top: 20px;
                right: 0;
            }
        }

        .movie-intro .labels {
            position: absolute;
            top: -30px;
            left: 0;
        }

        .movie-info br {
            display: block;
        }

        .poster {
            padding: 0 30px;
        }
    }
}

@media only screen and (min-width: 1440px) {
    #moviepage,
    #movieseriespage,
    #movieeventpage,
    #moviefestivalpage {
        .movie-intro {
            padding-top: 39px;
            margin-top: -96px;
            padding-bottom: 32px;

            h1 {
                font-size: 4.5em;
                padding-right: 60px;
            }
        }

        .doorlezer,
        .programme {
            &::before {
                display: none;
            }

            .label {
                left: 60px;
            }
        }

        .poster {
            padding: 0 60px;
        }
    }
}
