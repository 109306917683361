.press {
    .article-block {
        margin-bottom: 30px;

        .img-holder {
            overflow: hidden;
        }

        img {
            margin-bottom: 12px;
            transform: scale(1);
            transition: transform 0.3s ease;
        }

        h2 {
            font-size: 1.375em;
            margin-bottom: 0;
            line-height: 1.1;
        }

        svg {
            display: none;
        }
    }
}

.no-touchevents .press .article-block:hover .img-holder img {
    transform: scale(1.1);
}

.no-touchevents .press .article-block:hover svg {
    transform: translateX(10px);
}

#sidebar {
    .cta.presskit {
        a.button {
            background-color: $color2;
            color: #fff;
            font-weight: bold;
            padding: 12px 15px 14px;
            text-decoration: none;
            margin-top: 5px;
            display: inline-block;
            font-size: 1em;
        }
    }

    .cta.pressletter {
        background-color: #fff;

        .txt {
            p {
                font-family: Tiempo;
                font-size: 1em;
                color: rgba(59, 53, 48, 0.5);
            }
        }

        a.button {
            text-decoration: none;
            margin-bottom: 0;
        }
    }
}

@media only screen and (min-width: 768px) {
    .press {
        .article-block {
            margin-bottom: 40px;

            img {
                margin-bottom: 18px;
            }

            h2 {
                font-size: 1.875em;
                margin-bottom: 15px;
                padding-right: 10px;
            }

            svg {
                display: block;
                width: 32px;
                height: 20px;
            }
        }
    }

    #sidebar {
        .cta.presskit {
            a.button {
                font-size: 1.3em;
            }
        }

        .cta.pressletter {
            .txt {
                p {
                    font-size: 1.125em;
                }
            }
        }
    }
}

@media only screen and (min-width: 1024px) {
    .press {
        .article-block:nth-child(odd) {
            clear: both;
        }
    }
}
