.schedule-holder {
    position: relative;
    margin-bottom: 30px;

    .tabs-holder {
        .tab {
            display: inline-block;
            width: 33.33%;
            color: #9a968e;
            font-size: 1.125em;
            text-align: center;
            float: left;
            cursor: pointer;
            font-weight: bold;
            padding: 20px 0;

            &.active {
                background-color: #fff;
                color: $fontColor;
            }
        }
    }

    .schedule__daily-title {
        font-size: 1.25em;
        margin-bottom: 15px;

        @media only screen and (min-width: 768px) {
            font-size: 1.5em;
            margin-bottom: 18px;
        }
    }

    .schedule {
        background-color: #fff;
        padding: 0px 15px 0;

        &.first {
            padding-top: 18px;
        }

        h3 {
            font-weight: bold;
            font-size: 1.25em;
        }

        .day-selector {
            border: 2px solid $fontColor;
            margin-bottom: 15px;

            .current {
                padding: 15px 9px;
                font-weight: bold;
                cursor: pointer;
                position: relative;

                svg {
                    fill: $fontColor;
                    width: 12px;
                    height: 7px;
                    position: absolute;
                    top: 20px;
                    right: 15px;
                }
            }

            .list {
                display: none;

                .option {
                    font-weight: bold;
                    padding: 15px 9px;
                    cursor: pointer;
                }

                &.active {
                    display: block;
                }
            }
        }

        .item {
            display: block;
            position: relative;
            min-height: 46px;
            width: 100%;
            box-sizing: border-box;
            padding-right: 100px;
            border-bottom: 1px solid #ebeae8;
            transform: translateZ(0);

            .title {
                font-size: 0.9375em;
                font-weight: bold;
                padding: 13px 0 17px 0;
                display: block;

                .label {
                    color: $color5;
                    font-size: 0.65em;
                    text-transform: uppercase;
                    letter-spacing: 0.05em;
                    margin-top: 3px;
                }
            }

            .time,
            .info {
                position: absolute;
                top: 50%;
                right: 0;
                transform: translateY(-50%) translateZ(0);
                background-color: $color3;
                color: #fff;
                font-weight: bold;
                font-size: 0.875em;
                width: 55px;
                height: 35px;
                text-align: center;
                box-sizing: border-box;
                padding: 10px;

                &.info {
                    background-color: $color2;
                }
            }

            .time.unavailable {
                background-color: #d4d0c7;
                pointer-events: none;
            }
        }

        .bottom {
            display: none;

            &.visible {
                display: block;
            }
        }

        .buffer {
            height: 50px;
            color: $fontColor;
            font-weight: bold;
            font-size: 1.25em;
            padding-top: 13px;
            box-sizing: border-box;
            position: relative;
            cursor: pointer;

            svg {
                position: absolute;
                right: 20px;
                top: 50%;
                transform: translateY(-50%);
                width: 14px;
                height: 9px;
            }

            &.active {
                svg {
                    transform: translateY(-50%) rotate(180deg);
                }
            }

            &.hidden {
                visibility: hidden;
                height: 0px;
            }
        }
    }

    a.full-programme {
        color: #fff;
        background-color: $color1;
        font-weight: bold;
        font-size: 1.25em;
        padding: 19px 15px 0;
        height: 60px;
        box-sizing: border-box;
        display: block;
        text-decoration: none;
        transition: background 0.3s ease;
        margin-bottom: 15px;

        @media only screen and (min-width: 768px) {
            margin-bottom: 20px;
        }

        svg {
            position: absolute;
            right: 37px;
            top: 50%;
            transform: translateY(-50%);
            width: 9px;
            height: 14px;
        }
    }
}

.no-touchevents .schedule-holder.agenda .schedule .item::before {
    content: '';
    position: absolute;
    top: 0;
    left: -15px;
    width: 4px;
    height: 100%;
    opacity: 0;
    background-color: $fontColor;
    transition: opacity 0.6s ease;
}

.no-touchevents .schedule-holder.agenda .schedule .item .title {
    transition: transform 0.6s ease;
}

.no-touchevents .schedule-holder.agenda .schedule .item:hover .title {
    transform: translateX(-2px) translateZ(0) scale(1);
    -webkit-backface-visibility: hidden;
    transform-style: preserve-3d;
}

#full-list {
    height: 1px;
    overflow: hidden;
}

#full-list .item {
    opacity: 0;
}

#full-list.open {
    height: auto;
}

.no-touchevents .schedule-holder.agenda .schedule .item.special::before {
    background-color: $color5;
}

.no-touchevents .schedule-holder.agenda .schedule .item:hover::before {
    opacity: 1;
}

.no-touchevents .schedule-holder.agenda a.full-programme:hover,
.no-touchevents a.full-programme:hover {
    background-color: $color4;
}

p.no-tickets {
    margin-bottom: 0px;
    padding-bottom: 28px;
}

p.no-tickets.no-padding {
    margin-bottom: 0px;
    padding-bottom: 17px;
    font-size: 0.9375em;
    font-weight: bold;

    @media only screen and (min-width: 768px) {
        font-size: 1em;
    }

    @media only screen and (min-width: 1440px) {
        font-size: 1.125em;
    }
}

#main-scheduler {
    .schedule.first,
    #full-list {
        .item:nth-last-of-type(1) {
            border-bottom: 0;
        }
    }

    #full-list {
        .item:first-of-type {
            border-top: 1px solid #ebeae8;
        }
    }

    .inc-buffer {
        border-top: 1px solid #ebeae8;
    }
}

.no-touchevents #main-scheduler .item:hover .time::after,
.no-touchevents .schedule.first .item:hover .time::after {
    content: 'ticket';
    position: absolute;
    top: 0;
    left: 0px;
    width: 100%;
    height: 100%;
    background-color: #cc4e3d;
    color: #fff;
    box-sizing: border-box;
    padding-top: 10px;
}

.no-touchevents #main-scheduler .item:hover .time.info::after,
.no-touchevents .schedule.first .item:hover .time.info::after {
    content: 'info';
    background-color: #62a6af;
}

@media only screen and (min-width: 768px) {
    .schedule-holder {
        margin-bottom: 40px;

        .tabs-holder {
            .tab {
                font-size: 1.25em;
            }
        }

        .schedule {
            padding: 0px 20px 0;

            .first {
                padding-top: 10px;
            }

            h3 {
                font-size: 1.5em;
            }

            .item {
                min-height: 51px;
                padding-right: 68px;

                .title {
                    font-size: 1em;
                    padding: 13px 0 17px 0;
                }

                .time,
                .info {
                    font-size: 1em;
                    width: 60px;
                    height: 40px;
                    padding-top: 11px;
                }
            }

            .buffer {
                height: 60px;
                font-size: 1.5em;
                padding-top: 17px;

                svg {
                    width: 18px;
                    height: 12px;
                }
            }
        }

        a.full-programme {
            font-size: 1.5em;
            padding: 25px 20px 0;
            height: 75px;

            svg {
                right: 42px;
                width: 12px;
                height: 18px;
            }
        }
    }

    .no-touchevents .schedule-holder.agenda .schedule .item::before {
        left: -20px;
    }
}

@media only screen and (min-width: 1440px) {
    .schedule-holder {
        .schedule {
            padding-left: 30px;
            padding-right: 30px;

            h3 {
                font-size: 1.875em;
            }

            .item {
                .title {
                    font-size: 1.125em;
                }

                .time,
                .info {
                    font-size: 1.125em;
                }
            }
        }

        a.full-programme {
            padding-left: 30px;
            padding-right: 30px;

            svg {
                right: 51px;
            }
        }
    }

    .no-touchevents .schedule-holder.agenda .schedule .item::before {
        left: -30px;
    }
}
