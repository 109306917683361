#share-overlay,
#corona-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(250, 247, 237, 0.97);
    z-index: -1;
    opacity: 0;
    transition: opacity 0.3s ease;

    &.active {
        opacity: 1;
        z-index: 100;

        .inner {
            opacity: 1;
            transform: translate(-50%, -50%);

            .close-btn {
                opacity: 1;
            }
        }
    }

    .inner {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -30%);
        opacity: 0;
        transition: opacity 0.7s 0.2s ease, transform 0.7s 0.2s ease;

        .close-btn {
            position: relative;
            margin: 0 auto 40px;
            opacity: 0;
            transition: opacity 0.5s 0.8s ease;
        }

        h3 {
            font-family: Tiempo;
            font-weight: normal;
            font-style: normal;
            font-size: em(18px);
            margin-bottom: 35px;
        }

        ul {
            list-style: none;
            text-align: center;
            padding: 0;

            li {
                margin: 15px 0;

                &.whatsapp {
                    @media only screen and (min-width: 768px) {
                        display: none;
                    }
                }

                a {
                    font-size: 1.4375em;
                    font-weight: bold;
                    position: relative;
                    display: inline-block;

                    &::after {
                        content: '';
                        position: absolute;
                        bottom: -4px;
                        left: 0;
                        width: 100%;
                        height: 3px;
                        background-color: $color1;
                        display: none;
                    }

                    &:hover::after {
                        display: block;
                    }
                }
            }
        }
    }
}

#corona-overlay {
    background-color: rgba(250, 247, 237, 0.9);

    .inner {
        width: calc(100% - 30px);

        @media only screen and (min-width: 768px) {
            width: 700px;
        }
    }
}

@media only screen and (min-width: 768px) {
    #share-overlay .inner ul li a {
        font-size: 2.25em;
    }
}
