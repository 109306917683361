.accordion {
    margin-bottom: 50px;

    .item {
        border-bottom: 1px solid #e7e3da;

        &:nth-child(1) {
            border-top: 1px solid #e7e3da;
        }

        .title {
            box-sizing: border-box;
            padding: 15px 0;
            cursor: pointer;
            position: relative;

            h2 {
                font-size: 1.4375em;
                padding-right: 30px;
                margin-bottom: 0;
                line-height: 1;
            }

            h3 {
                margin-bottom: 0;
                line-height: 1.3;
                padding-right: 40px;
                font-size: 1.125em;
            }

            svg {
                width: 12px;
                height: 8px;
                position: absolute;
                top: 22px;
                right: 10px;
            }
        }

        .content {
            display: none;

            img {
                display: block;
                margin-bottom: 25px;
                width: 100%;
                height: auto;
            }

            h1,
            h2,
            h3,
            h4,
            h5,
            h6 {
                font-size: 1em;
                font-weight: bold;
            }
        }

        &.active {
            .title {
                svg {
                    transform: rotate(180deg);
                }
            }

            .content {
                display: block;
            }
        }
    }
}

@media only screen and (max-width: 768px) {
    .text-indent .accordion.faq h2 {
        margin-bottom: 10px;
    }
}

@media only screen and (min-width: 768px) {
    .accordion {
        .item {
            .title {
                padding: 20px 0 23px;

                h2 {
                    font-size: 2.25em;
                    padding-right: 60px;
                }

                h3 {
                    font-size: 1.3em;
                    padding-right: 55px;
                }

                svg {
                    width: 20px;
                    height: 13px;
                    right: 20px;
                    top: 32px;
                }
            }

            .content {
                padding-top: 5px;

                img {
                    margin-bottom: 40px;
                }

                h1,
                h2,
                h3,
                h4,
                h5,
                h6 {
                    font-size: 1.375em;
                }

                p {
                    margin-bottom: 0;
                }
            }
        }

        &.faq {
            .item {
                padding-top: 11px;
                padding-bottom: 11px;

                .title {
                    padding: 15px 0;

                    svg {
                        top: 24px;
                    }
                }
            }
        }
    }
}
