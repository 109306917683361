.videoplayer-holder {
    margin-bottom: 50px;
    position: relative;

    .plyr__controls,
    .plyr__control--overlaid {
        opacity: 0;
    }

    &.playing {
        .plyr__controls {
            opacity: 1;
        }

        .play {
            opacity: 0;
        }
    }

    .play {
        opacity: 1;
        position: absolute;
        top: 50%;
        left: 50%;
        width: 84px;
        height: 84px;
        text-indent: -999999em;
        overflow: hidden;
        cursor: pointer;
        background: url('/media/images/icn-play.svg') 0 0 no-repeat;
        background-size: contain;
        transform: translate(-50%, -50%);
        z-index: 2;
    }
}
