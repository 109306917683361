#archivepage {
    h1 {
        margin-bottom: 20px;
    }

    .dropdown {
        position: relative;
        background-color: #e7e3da;
        transform: translateX(-20px);
        padding: 0 20px;
        width: 100%;
        z-index: 3;
        margin-bottom: 1px;

        &.year-select {
            z-index: 4;
        }

        .current {
            font-size: 1em;
            font-weight: bold;
            cursor: pointer;
            padding: 20px 0;

            svg {
                position: absolute;
                top: 25px;
                right: 30px;
                width: 12px;
                height: 8px;
            }
        }

        .list {
            display: none;
            position: absolute;
            background-color: #fff;
            left: 0;
            top: 56px;
            width: 100%;
            z-index: 2;
            padding-top: 20px;
            padding-bottom: 5px;

            &.active {
                display: block;
            }

            .select {
                font-size: 1em;
                font-weight: bold;
                cursor: pointer;
                padding: 0px 0 26px 20px;
                color: $fontColor;
                text-decoration: none;
                display: block;

                &.active {
                    display: none;
                }
            }
        }
    }

    #search-archive {
        transform: translateX(-20px);
        margin-top: 30px;
        width: 100%;
        padding-right: 40px;

        &::after {
            content: '';
            position: absolute;
            bottom: -12px;
            left: 0;
            width: 205px;
            height: 2px;
            background-color: $color1;
        }

        input[type='text'] {
            border: none;
            background-color: transparent;
            font-size: em(16px);
            font-family: CalibreWeb;
            font-weight: normal;
        }

        input[type='submit'] {
            position: absolute;
            top: 0;
            right: 0;
            border: none;
            background-color: transparent;
            text-transform: uppercase;
            cursor: pointer;
            font-family: CalibreWeb;
            font-size: em(20px);
            font-weight: bold;
        }
    }
}

@media only screen and (min-width: 768px) {
    #archivepage {
        .dropdown {
            float: left;
            margin-right: 30px;
            background-color: transparent;
            transform: translateX(0px);
            padding: 0px;
            width: auto;
            margin-bottom: 0;

            .current {
                padding: 0 20px 0 0;
                font-size: em(24px);
                position: relative;
                display: inline-block;

                svg {
                    position: absolute;
                    top: 10px;
                    right: 0;
                    width: 11px;
                    height: 7px;
                    fill: #3b3530;
                }
            }

            .list {
                left: -20px;
                top: 30px;
                width: auto;
                font-size: 1.5em;
                padding: 7px 25px 7px 20px;
                text-transform: lowercase;

                .select {
                    display: inline-block;
                    margin: 4px 0 8px 0;
                    text-align: center;
                    padding: 0;
                }
            }
        }

        #search-archive {
            position: relative;
            float: right;
            width: 300px;
            margin: 0;
            padding: 0;
            transform: translateX(0);

            &::after {
                content: '';
                position: absolute;
                bottom: -12px;
                left: 0;
                width: 226px;
                height: 2px;
                background-color: $color1;
            }

            input[type='text'] {
                border: none;
                background-color: transparent;
                font-size: em(18px);
                font-family: CalibreWeb;
                font-weight: normal;
            }

            input[type='submit'] {
                position: absolute;
                top: 0;
                right: 0;
                border: none;
                background-color: transparent;
                text-transform: uppercase;
                cursor: pointer;
                font-family: CalibreWeb;
                font-size: em(24px);
                font-weight: bold;
            }
        }
    }

    .results-info {
        margin-top: 25px;
    }
}
