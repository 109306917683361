@import '~plyr/src/sass/plyr';

.plyr .plyr__progress input[type='range'],
.plyr .plyr__volume input[type='range'] {
    color: #fff !important;
}

.plyr--full-ui.plyr--video input[type='range']:active::-webkit-slider-thumb {
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.3);
    border: 2px #fff solid;
}

.plyr--full-ui.plyr--video input[type='range']:active::-moz-range-thumb {
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.3);
    border: 2px #fff solid;
}

.plyr--full-ui.plyr--video input[type='range']:active::-ms-thumb {
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.3);
    border: 2px #fff solid;
}
