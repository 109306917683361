.datepicker-container {
    position: absolute;
    background-color: #fff;
    box-sizing: border-box;
    z-index: 10;
    right: 0px;
    width: 315px;
}

#sidebar .datepicker-container {
    border-bottom: 1px solid #d4d0c7;
}

.suboptions .datepicker-container {
    width: 100%;
    left: 0px;
    top: 42px;
    max-width: 290px;
}

.datepicker__disclaimer {
    background-color: #70c4d0;
    color: #fff;
    font-size: 14px;
    padding: 14px;
    line-height: 1.2;
    margin-top: 10px;
}

@media only screen and (min-width: 1024px) {
    .suboptions .datepicker-container {
        left: 74px;
    }

    html:lang(en) .suboptions .datepicker-container {
        left: 49px;
    }
}

@media only screen and (min-width: 1440px) {
    .suboptions .datepicker-container {
        left: 70px;
    }

    html:lang(en) .suboptions .datepicker-container {
        left: 45px;
    }
}

.datepicker-container .inner {
    overflow: hidden;
}

.datepicker-container .title,
.datepicker-container .back,
.datepicker-container .next,
.datepicker-container .delete {
    position: relative;
    box-sizing: border-box;
    padding-top: 16px;
    height: 50px;
    text-align: center;
    font-family: CalibreWeb;
    color: $fontColor;
    font-weight: bold;
    font-size: 1.25em;
    background-color: #fff;
}

.datepicker-container .title .next,
.datepicker-container .title .prev {
    position: absolute;
    top: 10px;
    left: 19px;
    width: 30px;
    height: 30px;
    text-indent: -999999em;
    overflow: hidden;
    cursor: pointer;
    background: url('/media/images/arrow-right-black.svg') no-repeat 50%;
    background-size: 8px 12px;
}

.datepicker-container .title .prev {
    transform: rotate(180deg);
    transform-origin: center;
}

.datepicker-container .title .next,
.popup .title .next {
    left: inherit;
    right: 19px;
    background: url('/media/images/arrow-right-black.svg') no-repeat 50%;
    background-size: 8px 12px;
}

#date-select .datepicker .dates .day:hover {
    position: relative;
}

#date-select .datepicker .dates .day:hover span {
    position: relative;
    color: $fontColor;
}

#date-select .datepicker .dates .day:hover::before {
    content: '';
    position: absolute;
    top: 0px;
    left: 0px;
    background-color: #d4d0c7;
    color: $fontColor;
    width: 100%;
    height: 100%;
}

#date-select .datepicker {
    margin: 0 auto;
    padding: 0;
}

#date-select .datepicker .days div,
#date-select .datepicker .dates .day {
    margin: 2px 4px;
}

#date-select .datepicker .days {
    padding-left: 10px;
    padding-right: 10px;
}

/* stylelint-disable-next-line no-duplicate-selectors */
#date-select .datepicker .days div,
#date-select .datepicker .dates .day {
    float: left;
    display: block;
    width: 38px;
    margin: 0;
    text-align: center;
    padding: 14px 0;
    cursor: pointer;

    &.past,
    &.nil {
        cursor: default;
        pointer-events: none;
    }
}

@media only screen and (min-width: 360px) {
    .suboptions .datepicker-container {
        max-width: 300px;
    }

    #date-select .datepicker .days div,
    #date-select .datepicker .dates .day {
        width: 40px;
    }
}

@media only screen and (min-width: 768px) {
    .suboptions .datepicker-container {
        width: 315px;
        top: 50px;
        left: 60px;
        max-width: none;
    }

    html:lang(en) .suboptions .datepicker-container {
        left: 35px;
    }
}

#date-select .datepicker .days div {
    color: #666;
    font-weight: bold;
}

#date-select .datepicker .dates {
    padding: 0 10px;
    font-weight: bold;
}

#date-select .datepicker .dates .day {
    color: #d4d0c7;
    font-size: 1em;
    font-weight: bold;
    text-decoration: none;
}

#date-select .datepicker .dates .day.open {
    color: $fontColor;
    cursor: pointer;
}

#date-select .datepicker .dates .day.active,
#date-select .datepicker .dates .day.today {
    color: $color1;
    position: relative;
}

@media only screen and (min-width: 768px) {
    .datepicker-container {
        width: auto;
    }

    #date-select .datepicker {
        max-width: 100%;
    }

    #date-select .datepicker .days div,
    #date-select .datepicker .dates .day {
        width: 42px;
    }

    #sidebar #date-select .datepicker .days div,
    #sidebar #date-select .datepicker .dates .day {
        width: 48px;
    }
}
