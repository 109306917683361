.container {
    width: 100%;
    position: relative;

    .inner-container {
        .contentpage {
            margin-top: 18px;

            .text-indent {
                h1 {
                    font-size: 2.25em;
                    margin-bottom: 15px;
                }

                p,
                li,
                table {
                    font-size: 1em;
                    line-height: 1.5;
                }
            }
        }

        #content {
            margin: 0 auto;
            padding: 0 15px;
            max-width: 550px;
            position: relative;

            .icons {
                display: inline-block;

                svg {
                    transition: fill 0.3s ease;
                    fill: #d5d0c7;
                    float: left;
                    display: inline-block;
                    cursor: pointer;

                    &.share {
                        width: 19px;
                        height: 17px;
                    }

                    &:hover {
                        fill: $color4;
                    }
                }
            }
        }

        #sidebar {
            width: 100%;
        }
    }
}

main {
    padding-top: 60px;
}

@media only screen and (min-width: 768px) {
    .container {
        margin: 0 auto;
        width: 720px;

        .inner-container {
            width: 700px;
            margin: 0 auto;

            .contentpage {
                margin-top: 80px;

                .text-indent {
                    h1 {
                        font-size: 4.5em;
                        margin-bottom: 25px;
                    }

                    p,
                    li,
                    table {
                        font-size: 1.125em;
                        margin-bottom: 30px;
                    }

                    p.buttons a:first-child {
                        margin-right: 20px;
                    }

                    li {
                        margin-bottom: 0;
                    }

                    &.intro p {
                        font-size: 1em;
                    }
                }
            }

            #content {
                float: left;
                width: 380px;
                margin: 0 20px 0 0;
                padding: 0;
                max-width: none;
            }

            #sidebar {
                float: right;
                width: 300px;
            }
        }
    }

    main {
        padding-top: 80px;
    }
}

@media only screen and (min-width: 1024px) {
    .container {
        width: 960px;

        .inner-container {
            width: 940px;

            #content {
                width: 620px;

                .icons {
                    float: right;
                    transform: translateY(0px);
                }
            }
        }
    }
}

@media only screen and (min-width: 1280px) {
    .container {
        width: 1180px;

        .inner-container {
            width: 940px;
        }
    }
}

@media only screen and (min-width: 1440px) {
    .container {
        width: 1280px;

        .inner-container {
            width: 1180px;

            #content {
                width: 780px;
                margin-right: 40px;
            }

            #sidebar {
                width: 360px;
            }
        }
    }
}

@media only screen and (min-width: 1680px) {
    .container {
        width: 1600px;
    }
}
