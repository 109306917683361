#homepage #slider {
    #slider-logo {
        position: absolute;
        display: block;
        width: 180px;
        height: 77px;
        top: 50%;
        left: 50%;
        transform: translate3d(-50%, -50%, 0);
        z-index: 2;

        @media only screen and (min-width: 768px) {
            width: 220px;
            height: 94px;
        }

        @media only screen and (min-width: 1024px) {
            width: 330px;
            height: 140px;
        }
    }
}

#homepage {
    .homepage__mobile-cta-holder {
        @media only screen and (min-width: 768px) {
            display: none;
        }
    }

    .homepage__desktop-cta-holder {
        display: none;

        @media only screen and (min-width: 768px) {
            display: block;
        }
    }
}

#homepage .container .inner-container {
    .holder {
        display: flex;
        flex-direction: column;
    }

    #content {
        order: 2;

        .highlights {
            margin-bottom: 40px;

            h2 {
                font-size: 1.875em;
                margin-bottom: 20px;
                line-height: normal;
            }

            a.btn-link {
                display: block;
                position: absolute;
                right: 30px;
                top: 15px;
                color: #9a968e;
                text-decoration: underline;
                font-weight: 700;
                font-size: em(13);
            }

            .highlight {
                &:nth-of-type(3) {
                    display: none;
                }

                .img-holder {
                    position: relative;
                    margin-bottom: 15px;
                    overflow: hidden;

                    img {
                        display: block;
                        width: 100%;
                        height: auto;
                    }

                    .labels {
                        position: absolute;
                        bottom: 12px;
                        left: 0;

                        span {
                            font-size: 0.6875em;
                            text-transform: uppercase;
                            padding: 6px 8px;
                            color: #fff;
                            float: left;
                            margin-right: 5px;
                            font-weight: bold;
                            letter-spacing: 0.05em;
                        }
                    }
                }

                .title {
                    margin-bottom: 14px;

                    .date-time {
                        font-size: 1em;
                        color: #9a968e;
                        font-weight: bold;
                    }

                    h3 {
                        font-size: 1.4375em;
                        margin-bottom: 5px;
                    }
                }

                p {
                    font-size: 0.875em;
                    line-height: 1.6;
                    margin-bottom: 6px;
                }
            }
        }

        .bar-restaurant {
            h2 {
                font-size: 1.875em;
                margin-bottom: 20px;
                line-height: normal;
            }

            img {
                display: block;
                width: 100%;
                height: auto;
                margin-bottom: 20px;
            }

            .btns-holder {
                a {
                    width: 127px;
                    background-color: #fff;
                    display: inline-block;
                    padding: 15px 0 17px;
                    text-align: center;
                    float: left;

                    &.reservation {
                        margin-left: 8px;
                        width: 115px;
                        color: #fff;
                        background-color: $color2;
                    }
                }
            }
        }

        .news {
            margin-top: 35px;

            h2 {
                font-size: 1.875em;
                margin-bottom: 20px;
                line-height: normal;
            }

            .news-block {
                &:nth-of-type(2) {
                    display: none;
                }

                h2 {
                    font-size: 1.875em;
                    margin-bottom: 20px;
                    line-height: normal;
                }

                img {
                    display: block;
                    width: 100%;
                    height: auto;
                }

                .inner {
                    padding: 25px 20px;
                    background-color: #fff;
                }

                h3 {
                    font-size: 1.375em;
                    line-height: 1.1;
                    margin-bottom: 20px;
                }

                p {
                    line-height: 1.5;
                    font-size: 0.875em;
                    margin-bottom: 0;
                }
            }
        }
    }

    #sidebar {
        order: 1;
        margin-top: 0;
    }

    .lumiere-live {
        display: none;
    }
}

.no-touchevents #homepage .container .inner-container #content .highlights .highlight:hover .read-more svg {
    transform: translateX(10px);
}

@media only screen and (min-width: 768px) {
    #homepage #slider {
        margin-bottom: 65px;
    }

    #homepage .container .inner-container {
        .holder {
            display: block;
            flex-direction: auto;
        }

        #content {
            order: 1;

            .highlights {
                a.btn-link {
                    top: 29px;
                    right: 0px;
                    font-size: em(18);
                }
            }

            // TODO: Refactor this.
            /* stylelint-disable-next-line no-duplicate-selectors */
            .highlights {
                margin-bottom: 80px;

                h2 {
                    font-size: 3em;
                    margin-bottom: 28px;
                }

                .highlight {
                    .img-holder {
                        position: relative;
                        margin-bottom: 20px;

                        img {
                            display: block;
                            width: 100%;
                            height: auto;
                        }

                        .labels {
                            position: absolute;
                            bottom: 12px;
                            left: 0;

                            span {
                                font-size: 0.875em;
                                text-transform: uppercase;
                                padding: 6px 8px;
                                color: #fff;
                                float: left;
                                margin-right: 5px;
                            }
                        }
                    }

                    .title {
                        margin-bottom: 14px;

                        .date-time {
                            font-size: em(18px);
                        }

                        h3 {
                            font-size: em(30px);
                            margin-bottom: 5px;
                        }
                    }

                    p {
                        font-size: 1em;
                    }
                }
            }

            .bar-restaurant {
                h2 {
                    font-size: 3em;
                    margin-bottom: 28px;
                }

                .intro {
                    margin-bottom: 40px;
                }

                img {
                    margin-bottom: 32px;
                }

                .btns-holder {
                    a {
                        width: 127px;
                        background-color: #fff;
                        display: inline-block;
                        padding: 15px 0 17px;
                        text-align: center;
                        float: left;

                        &.reservation {
                            margin-left: 8px;
                            width: 115px;
                            color: #fff;
                            background-color: $color2;
                        }
                    }
                }
            }

            .news {
                margin-top: 60px;

                h2 {
                    font-size: 3em;
                    margin-bottom: 28px;
                }

                .news-block {
                    .inner {
                        padding: 25px 30px;
                        height: 325px;
                        box-sizing: border-box;
                    }

                    h3 {
                        font-size: 1.875em;
                        margin-bottom: 12px;
                    }

                    p {
                        font-size: 1em;
                    }
                }
            }
        }

        #sidebar {
            order: 2;
            margin-top: 0;

            .schedule-holder.agenda {
                margin-top: 6px;
            }
        }
    }
}

@media only screen and (min-width: 1024px) {
    #homepage .container .inner-container {
        #content {
            .highlights {
                .highlight {
                    width: 300px;
                    float: left;
                    display: inline-block;

                    &:nth-of-type(3) {
                        display: inline-block;
                        margin-left: 20px;
                    }
                }
            }

            .news {
                .news-block {
                    width: 300px;
                    float: left;
                    display: inline-block;

                    &:nth-of-type(2) {
                        display: inline-block;
                        margin-left: 20px;
                    }

                    .inner {
                        padding: 25px 30px;
                    }

                    h3 {
                        font-size: 1.5em;
                    }
                }
            }
        }

        .lumiere-live {
            display: block;
            margin-top: 80px;
            position: relative;
            clear: both;

            h2 {
                font-size: 2.25em;
                margin-bottom: 22px;
            }

            a.follow {
                position: absolute;
                top: 28px;
                right: 0;
                font-size: 1.125em;
                font-weight: bold;
                padding-left: 23px;
                text-transform: lowercase;
                color: #c1beb5;
                background:
                    url('/media/images/icn-instagram-dark.svg') 0 3px
                    no-repeat;
                background-size: 15px 15px;
            }

            .insta-photos {
                a {
                    display: inline-block;
                    margin-right: 20px;
                    float: left;

                    &:last-of-type {
                        margin-right: 0;
                    }
                }

                a:nth-child(6n + 6) {
                    margin-right: 0px;
                }

                img {
                    display: block;
                    width: 140px;
                    height: 140px;
                    margin-bottom: 20px;
                    float: left;
                    object-fit: cover;
                }
            }
        }
    }
}

@media only screen and (min-width: 1440px) {
    #homepage .container .inner-container {
        #content {
            .highlights {
                .highlight {
                    width: 380px;
                }
            }

            .bar-restaurant {
                .text-indent {
                    p {
                        font-size: em(18px);

                        .button {
                            padding: 11px 30px 12px;
                        }
                    }
                }
            }

            .news {
                .news-block {
                    width: 380px;

                    h3 {
                        font-size: 1.875em;
                    }

                    .inner {
                        height: 290px;
                    }
                }
            }
        }

        .lumiere-live .insta-photos {
            img {
                width: 180px;
                height: 180px;
                object-fit: cover;
            }
        }
    }
}

.movies-carousel {
    width: 100%;
    margin-top: 60px;

    h2 {
        font-size: 1.875em;
        line-height: normal;

        @media only screen and (min-width: 768px) {
            font-size: 3em;
        }
    }

    .swiper-slide {
        a {
            aspect-ratio: 10/14;
            display: block;
        }

        img {
            display: block;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
}
