#movieoverviewpage,
#archivepage {
    .movies-holder--list {
        @media only screen and (min-width: 768px) {
            margin-top: 25px;
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
        }

        &.less-margin {
            margin-top: 90px;
        }

        &.no-margin-top {
            margin-top: 0px;
        }

        .item {
            display: block;
            position: relative;
            padding: 0;
            margin-bottom: 35px;
            border-bottom: none;

            @media only screen and (max-width: 767px) {
                .img-holder {
                    display: block;
                    padding-bottom: 63%;

                    img {
                        position: absolute;
                        width: 100%;
                        height: 100%;
                        top: 0;
                        left: 0;
                        object-fit: cover;
                    }
                }
            }

            @media only screen and (min-width: 768px) {
                background-color: #fff;
                margin-bottom: 20px;
            }

            @media only screen and (min-width: 768px) and (max-width: 1023px) {
                display: flex;
                flex-direction: column;
            }

            @media only screen and (min-width: 1024px) {
                display: flex;
                width: 100%;
                margin-left: 0;
                min-height: 260px;

                &:nth-child(3n + 1) {
                    margin-left: 0;
                }

                &:nth-child(odd) {
                    margin-left: 0;
                }
            }

            @media only screen and (min-width: 1440px) {
                min-height: 280px;
            }

            .img-holder {
                @media only screen and (min-width: 1024px) {
                    width: 330px;
                    float: left;
                    height: 100%;
                }

                @media only screen and (min-width: 1440px) {
                    width: 400px;
                }

                img {
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    top: 0;
                    left: 0;
                    object-fit: cover;
                }
            }

            h2 {
                font-size: 1.34em;
                line-height: 1;
                margin-bottom: 4px;

                @media only screen and (min-width: 768px) {
                    font-size: 1.625em;
                }

                @media only screen and (min-width: 1024px) {
                    margin-top: 0;
                    font-size: 2.25em;
                }
            }

            h2 span {
                font-weight: 400;
                margin-right: 5px;
            }

            span.info {
                display: none;
            }

            .labels {
                display: none;
            }

            .labels--mobile {
                display: block;
                left: 0;
                right: auto;
                top: auto;
                bottom: 12px;
            }

            .info-wrapper {
                padding: 18px 15px 20px;
                background-color: #fff;

                @media only screen and (min-width: 1024px) {
                    display: flex;
                    flex-direction: column;
                    flex: 1;
                }

                @media only screen and (min-width: 768px) and (max-width: 1023px) {
                    display: flex;
                    flex-direction: column;
                    flex-grow: 1;
                }

                @media only screen and (min-width: 1024px) {
                    padding: 20px 25px;
                }

                @media only screen and (min-width: 1440px) {
                    padding: 30px 55px;
                }

                h2 {
                    margin-bottom: 10px;
                }

                p {
                    margin-bottom: 0;
                    font-size: 0.875em;

                    @media only screen and (min-width: 768px) {
                        font-size: 1em;
                    }

                    @media only screen and (min-width: 1440px) {
                        font-size: 1.125em;
                    }
                }

                .info-wrapper__content {
                    display: block;
                    margin-bottom: 20px;
                }

                .movie-info {
                    color: #70c4d0;
                    line-height: 1.4;
                    margin-top: 10px;
                    font-size: 0.75em;

                    @media only screen and (min-width: 768px) {
                        font-size: 0.875em;
                        line-height: 1.25;
                    }

                    @media only screen and (min-width: 1440px) {
                        font-size: 0.938em;
                    }
                }

                .movie-warning {
                    margin-top: 18px;
                    padding: 10px;
                    background-color: #f1f0ef;
                    font-size: 14px;
                    line-height: 1;
                    font-weight: 700;
                    display: inline-block;
                }

                .mobile-content {
                    display: block;
                }

                .time-ticket-info {
                    margin-top: 20px;

                    @media only screen and (min-width: 768px) {
                        margin-top: auto;
                    }

                    .time {
                        width: 55px;
                        height: 35px;
                        float: left;
                        margin-left: 5px;
                        background-color: #ff6f5c;
                        color: #fff;
                        font-size: 0.875em;
                        text-align: center;
                        font-weight: bold;
                        padding-top: 10px;
                        box-sizing: border-box;
                        margin-bottom: 5px;

                        @media only screen and (min-width: 1440px) {
                            font-size: 1em;
                            width: 60px;
                            height: 40px;
                            padding-top: 12px;
                        }

                        &:first-of-type {
                            margin-left: 0;
                        }

                        &:hover::after {
                            content: 'ticket';
                            position: absolute;
                            top: 0;
                            left: 0px;
                            width: 100%;
                            height: 100%;
                            background-color: #cc4e3d;
                            color: #fff;
                            box-sizing: border-box;
                            padding-top: 10px;
                        }
                    }
                }

                .link-to-entry {
                    display: inline-block;
                    padding: 11px 12px;
                    margin-left: 0;
                    background-color: #ff6f5c;
                    color: #fff;
                    font-size: 0.875em;
                    text-align: center;
                    font-weight: 700;
                    box-sizing: border-box;
                    line-height: 1;

                    @media only screen and (min-width: 1440px) {
                        font-size: 1em;
                        padding: 12px 12px;
                    }

                    &:hover {
                        background-color: #cc4e3d;
                    }
                }

                .expected-on {
                    display: inline-block;
                    padding: 11px 12px;
                    margin-left: 0;
                    background-color: #3b3530;
                    color: #fff;
                    font-size: 0.875em;
                    text-align: center;
                    font-weight: 700;
                    box-sizing: border-box;
                    line-height: 1;

                    @media only screen and (min-width: 1440px) {
                        font-size: 1em;
                        padding: 12px 12px;
                    }
                }
            }
        }

        .overlay {
            display: none;
        }
    }
}

.no-touchevents #movieoverviewpage .movies-holder--list .item:hover .labels,
.no-touchevents #archivepage .movies-holder--list .item:hover .labels {
    transform: translateX(0);
}
