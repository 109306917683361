header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 60px;
    z-index: 100;

    #header {
        background-color: #fff;
        height: 60px;
        position: relative;

        #hamburger {
            position: absolute;
            top: 17px;
            left: 15px;
            width: 24px;
            height: 16px;
            cursor: pointer;
            transform-style: preserve-3d;
            padding-bottom: 12px;

            span {
                position: absolute;
                left: 0;
                height: 2px;
                width: 100%;
                background-color: #3b3530;
                display: block;
                transition: all 0.1s linear;

                &.line1 {
                    top: 0;
                }

                &.line2 {
                    top: 7px;
                }

                &.line3 {
                    top: 14px;
                }
            }

            &::after {
                content: 'menu';
                display: block;
                font-size: em(11px);
                font-weight: 600;
                text-align: center;
                position: absolute;
                bottom: 0;
            }
        }

        .logo {
            position: absolute;
            top: 9px;
            left: 64px;
            width: 99px;
            height: 42px;
            display: block;
            text-indent: -999999em;
            overflow: hidden;
            background: url('/media/images/logo.svg') 0 0 no-repeat;
            background-size: 99px 42px;
        }

        .icons-holder {
            position: absolute;
            top: 0;
            right: 2px;

            .cart {
                position: absolute;
                top: 0;
                right: 0;
                cursor: pointer;
                width: 40px;
                height: 60px;
                background: url('/media/images/icn-cart.svg') 50% 50% no-repeat;
                background-size: 24px 21px;
            }

            .cart::after {
                content: attr(data-cart-amount);
                display: block;
                position: absolute;
                top: 50%;
                left: 5px;
                background-color: $red;
                border-radius: 50%;
                font-weight: 700;
                font-size: 0.8em;
                width: 20px;
                height: 20px;
                text-align: center;
                color: white;
                line-height: 1.5;
                transform: scale(0);
                transform-origin: center center;
                transition: transform 0.6s ease;
            }

            .cart.amount::after {
                transform: scale(1);
            }

            .my-lumiere {
                display: none;
            }

            .search-icon {
                position: absolute;
                top: 0;
                right: 40px;
                width: 40px;
                height: 60px;
                background-position: 50% 50%;
                background-repeat: no-repeat;
                background-size: 19px 18px;
                background-image: url('/media/images/icn-search.svg');
                cursor: pointer;
            }

            .search-icon::before {
                content: '';
                display: block;
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background-color: $color4;
                transform: translateY(-100%);
                transition: transform 0.6s ease 0.15s;
            }

            .search-icon::after {
                content: '';
                display: block;
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background-position: 50% 50%;
                background-repeat: no-repeat;
                background-size: 19px 18px;
                background-image: url('/media/images/icn-search-white.svg');
                opacity: 0;
                transition: opacity 0.6s ease 0.15s;
            }

            .search-icon.active::after {
                opacity: 1;
                transition: opacity 0.6s ease;
            }

            .search-icon.active::before {
                transform: translateY(0%);
                transition: transform 0.6s ease;
            }

            .lang-switch {
                position: absolute;
                top: 0;
                right: 80px;
                width: 45px;
                height: 60px;
                cursor: pointer;
                text-transform: uppercase;
                font-family: CalibreWeb;
                font-weight: bold;

                span.current {
                    position: absolute;
                    top: 0px;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    display: block;
                    text-align: center;
                    font-size: 0.84375em;
                    background-color: #fff;
                    transition: color 0.6s ease;

                    span {
                        position: relative;
                        display: inline-block;
                        padding-right: 12px;
                        background: url('/media/images/arrow-down.svg') 100% 50% no-repeat;
                        background-size: 8px 5px;
                        margin-top: 24px;
                        z-index: 10;

                        &::after {
                            position: absolute;
                            display: block;
                            top: 0;
                            left: 0;
                            height: 100%;
                            width: 100%;
                            background: url('/media/images/arrow-down-white.svg') 100% 50% no-repeat;
                        }
                    }

                    &::after {
                        content: '';
                        display: block;
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        background-color: $color4;
                        transform: translateY(-100%);
                        transition: transform 0.6s ease;
                    }
                }

                .lang-list {
                    display: block;
                    margin-top: 60px;
                    background-color: #fff;
                    transform: translateY(-100%);
                    transition: transform 0.6s ease;

                    a {
                        width: 45px;
                        height: 60px;
                        display: block;
                        text-align: center;
                        font-weight: bold;
                        font-size: 0.84375em;
                        padding-top: 25px;
                        box-sizing: border-box;
                    }

                    .active {
                        display: none;
                    }
                }

                &.active {
                    background-color: $color4;

                    span.current {
                        color: #fff;

                        span {
                            background: url('/media/images/arrow-down-white.svg') 100% 50% no-repeat;
                            background-size: 8px 5px;
                        }

                        &::after {
                            transform: translateY(0%);
                        }
                    }

                    .lang-list {
                        transform: translateY(0%);
                    }
                }
            }
        }
    }
}

#search {
    display: block;
    position: fixed;
    width: 100%;
    height: 0px;
    top: 60px;
    left: 0;
    z-index: 100;
    color: #fff;
    overflow: hidden;
    transition: height 0s 0.8s;

    .container {
        width: auto;
        height: 100%;
        padding: 23px 32px;
        background-color: $color4;
        box-sizing: border-box;
        transform: translateY(-150%);
        transition: transform 0.6s ease;
    }

    label {
        display: block;
        margin-bottom: 15px;
        font-size: 0.875em;
    }

    input[type='text'] {
        width: 100%;
        box-sizing: border-box;
        height: 60px;
        border: 0;
        padding: 0 0 5px 0px;
        background-color: transparent;
        font-size: 2.438em;
        font-weight: bold;
        font-family: CalibreWeb;
        float: left;
        color: #fff;

        &:focus {
            outline: none;
        }

        &::-webkit-input-placeholder { /* WebKit, Blink, Edge */
            color: #fff;
        }

        &:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
            color: #fff;
            opacity: 1;
        }

        &::-moz-placeholder { /* Mozilla Firefox 19+ */
            color: #fff;
            opacity: 1;
        }

        &:-ms-input-placeholder { /* Internet Explorer 10-11 */
            color: #fff;
        }

        &::-ms-input-placeholder { /* Microsoft Edge */
            color: #fff;
        }
    }

    input[type='submit'] {
        display: none;
    }

    &.active {
        height: 100%;
        transition: height 0s;
    }

    &.active .container {
        transform: translateY(0);
        transition: transform 0.6s ease 0.15s;
    }

    @media only screen and (min-width: 768px) {
        top: 80px;
    }
}

@media only screen and (min-width: 768px) {
    header {
        top: 0;
        height: 80px;

        #header {
            width: 540px;
            height: 80px;

            #hamburger {
                top: 24px;
                left: 20px;
                width: 32px;
                height: 21px;
                padding-bottom: 20px;

                span {
                    height: 3px;

                    &.line1 {
                        top: 0;
                    }

                    &.line2 {
                        top: 9px;
                    }

                    &.line3 {
                        top: 18px;
                    }
                }

                &::after {
                    font-size: em(15px);
                }
            }

            .logo {
                top: 12px;
                left: 85px;
                width: 132px;
                height: 56px;
                background: url('/media/images/logo.svg') 0 0 no-repeat;
                background-size: 132px 56px;
            }

            .icons-holder {
                position: absolute;
                top: 0;
                right: 0;

                .cart {
                    width: 60px;
                    height: 80px;
                    background-size: 28px 24px;
                }

                .my-lumiere {
                    position: absolute;
                    top: 0;
                    right: 60px;
                    display: block;
                    width: 60px;
                    height: 80px;
                    background: url('/media/images/icn-mijnlumiere.svg') 50% 50% no-repeat;
                    background-size: 24px 23px;
                }

                .search-icon {
                    right: 120px;
                    width: 60px;
                    height: 80px;
                    background-size: 25px 24px;
                }

                .search-icon::after {
                    background-size: 25px 24px;
                }

                .lang-switch {
                    right: 180px;
                    width: 60px;
                    height: 80px;

                    span.current {
                        position: absolute;
                        font-size: 1.125em;

                        span {
                            margin-top: 30px;
                        }
                    }

                    .lang-list {
                        margin-top: 80px;

                        a {
                            height: 80px;
                            padding-top: 30px;
                            width: 60px;
                            font-size: 1.125em;
                        }
                    }
                }
            }
        }
    }

    #search {
        .container {
            padding-left: 94px;
            margin: 0;
            padding-top: 23px;
            width: 100%;
        }

        label {
            font-size: 1em;
        }

        input[type='text'] {
            font-size: 3.25em;
        }

        &.active {
            height: 140px;
        }
    }
}

@media only screen and (min-width: 1024px) {
    header {
        #header {
            width: 620px;

            #hamburger {
                left: 40px;
            }

            .logo {
                left: 112px;
            }
        }
    }
}
