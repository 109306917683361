#search-results {
    .movies-holder {
        margin-bottom: 50px;

        .item {
            position: relative;
            margin-bottom: 30px;
            display: block;

            .img-holder {
                margin-bottom: 10px;
                position: relative;

                img {
                    display: block;
                    width: 100%;
                    height: auto;
                }

                .labels {
                    position: absolute;
                    bottom: 12px;
                    left: 0;
                }
            }

            h2 {
                margin-bottom: 0;
                line-height: 1;
            }

            span.info {
                font-weight: bold;
                text-transform: lowercase;
                color: #9a968e;
                display: block;
            }
        }
    }

    ul.other-results {
        li {
            a {
                color: $fontColor;
            }
        }
    }
}

@media only screen and (min-width: 768px) {
    #search-results {
        .movies-holder {
            .item {
                span.info {
                    font-size: 1.125em;
                }
            }
        }
    }
}

@media only screen and (min-width: 1024px) {
    #search-results {
        .movies-holder {
            .item {
                width: 300px;
                float: left;
                display: inline-block;

                &:nth-child(even) {
                    margin-left: 20px;
                }

                &:nth-child(odd) {
                    clear: both;
                }
            }
        }
    }
}

@media only screen and (min-width: 1440px) {
    #search-results {
        .movies-holder {
            .item {
                width: 380px;
            }
        }
    }
}
