#organizepage {
    .article-block .article-text {
        height: 90px;
    }

    @media only screen and (min-width: 768px) {
        .article-block .article-text {
            height: 125px;
        }
    }
}

#singleorganizepage {
    .container .inner-container .contentpage .text-indent h1 {
        font-size: 1.875em;
        margin-bottom: 20px;
    }

    @media only screen and (min-width: 768px) {
        .container .inner-container .contentpage .text-indent h1 {
            font-size: 3em;
            margin-bottom: 38px;
        }

        .container .inner-container #sidebar .cta-contact .txt p {
            padding-right: 55px;
        }
    }
}

#organizepage,
#singleorganizepage {
    .container .inner-container #sidebar .cta.contact .txt .read-more {
        display: inline-block;
    }

    @media only screen and (min-width: 768px) {
        .container .inner-container #sidebar .cta.contact .txt p {
            padding-right: 55px;
        }
    }
}

.no-touchevents #organizepage .container .inner-container #sidebar .cta.contact .txt .read-more:hover svg,
.no-touchevents #singleorganizepage .container .inner-container #sidebar .cta.contact .txt .read-more:hover svg {
    transform: translateX(10px);
}
