@import '/node_modules/swiper/swiper-bundle.min.css';

:root {
    --swiper-navigation-size: 30px;
    --swiper-theme-color: $fontColor;
}

.swiper {
    padding-bottom: 50px;
}

.swiper-pagination {
    bottom: 25px;
}

.swiper-pagination-bullet-active {
    background-color: $fontColor;
}

.swiper-container {
    position: relative;
}

.swiper-button-prev--custom,
.swiper-button-next--custom {
    display: none;
    position: absolute;
    bottom: 0;
    cursor: pointer;
    width: 12px;
    height: auto;
    z-index: 1;

    @media only screen and (min-width: 1024px) {
        display: block;
    }

    &.swiper-button-disabled {
        opacity: 0.5;
    }

    svg {
        width: 100%;

        path {
            fill: $fontColor;
        }
    }
}

.swiper-button-prev--custom {
    transform: rotate(180deg);
    left: 0;
}

.swiper-button-next--custom {
    right: 0;
}
