@font-face {
    font-family: CalibreWeb;
    src:
        url('/media/fonts/CalibreWeb-Semibold.woff2') format('woff2'),
        url('/media/fonts/CalibreWeb-Semibold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: CalibreWeb;
    src:
        url('/media/fonts/CalibreWeb-Regular.woff2') format('woff2'),
        url('/media/fonts/CalibreWeb-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: Tiempo;
    src:
        url('/media/fonts/Tiempo-.woff2') format('woff2'),
        url('/media/fonts/Tiempo-.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: Tiempo;
    src:
        url('/media/fonts/Tiempo-Italic.woff2') format('woff2'),
        url('/media/fonts/Tiempo-Italic.woff') format('woff');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}
