/* stylelint-disable scss/dollar-variable-pattern */
/* stylelint-disable scss/double-slash-comment-whitespace-inside */

/* Coloring */
$bgColor: #faf7ed;
$fontColor: #3b3530;
$color1: #34c4ad; //green
$color2: #70c4d0; //blue
$color3: #ff6f5c; //red
$color4: #e6c576; //brown
$color5: #a272b8; //purple

$red: #ff6f5c; //red
$green: #34c4ad;
