#movieeventpage {
    .programme {
        margin-top: 70px;
        margin-bottom: 40px;

        .item {
            margin: 10px 0;

            &:nth-of-type(1) {
                margin-top: 5px;
            }

            &:nth-last-of-type(1) {
                margin-bottom: 0;
            }

            span.time {
                display: inline-block;
                width: 33px;
                padding-right: 44px;
                position: relative;
                font-size: 0.75em;
                float: left;

                &::after {
                    content: '';
                    position: absolute;
                    top: 7px;
                    right: 8px;
                    height: 2px;
                    width: 30px;
                    background-color: $color4;
                }
            }

            span.title {
                font-family: Tiempo;
                font-size: 0.75em;
                margin-top: 1px;
                display: inline-block;
                line-height: 1.3;
                width: calc(100% - 77px);
            }
        }
    }

    .inpage-movie {
        .img-holder {
            img {
                width: 100%;
                height: auto;
                display: block;
            }
        }

        .movie-intro {
            margin-top: 0;

            a {
                text-decoration: none;
            }

            h1 {
                font-size: 1.25em;
            }

            .labels {
                margin-left: 20px;
            }
        }
    }
}

:lang(en) #movieeventpage .programme .item span.time {
    width: 45px;
}

@media only screen and (min-width: 768px) {
    #movieeventpage {
        .programme {
            margin-top: 100px;
            margin-bottom: 70px;

            .item {
                margin: 15px 0;

                span.time {
                    width: 35px;
                    padding-right: 65px;
                    font-size: 1em;

                    &::after {
                        top: 8px;
                        right: 10px;
                        width: 40px;
                    }
                }

                span.title {
                    font-size: 1em;
                    width: calc(100% - 100px);
                    margin-top: -1px;
                }
            }
        }

        .inpage-movie {
            margin-top: 80px;

            .movie-intro {
                padding-bottom: 12px;
                margin-bottom: 25px;

                h1 {
                    font-size: 2.25em;
                }

                p {
                    font-size: em(18px);
                }

                .labels {
                    margin-left: 20px;
                }
            }
        }

        .kijkwijzer {
            margin-bottom: 0;
        }
    }

    :lang(en) #movieeventpage .programme .item span.time {
        width: 55px;
    }
}

@media only screen and (min-width: 1024px) {
    #movieeventpage {
        .programme {
            .item {
                margin: 30px 0;

                span.time {
                    width: 45px;
                    font-size: 1.125em;

                    &::after {
                        top: 8px;
                        right: 10px;
                        width: 40px;
                    }
                }

                span.title {
                    font-size: 1.125em;
                    width: calc(100% - 110px);
                    margin-top: -2px;
                }
            }
        }

        .inpage-movie {
            .movie-intro {
                .labels {
                    margin-left: 30px;
                }
            }
        }
    }

    :lang(en) #movieeventpage .programme .item span.time {
        width: 65px;
    }
}

@media only screen and (min-width: 1440px) {
    #movieeventpage {
        .inpage-movie {
            .movie-intro {
                .labels {
                    margin-left: 60px;
                }
            }
        }
    }
}
